import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IContract } from '../contract-template';
import { ContractService } from '../contract.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../core/popup/confirmation/confirmation.component';
// import { ConfirmationComponent } from '../../core/popup/confirmation/confirmation.component';
@Component({
  selector: 'app-contractdelete',
  templateUrl: './contract-delete.component.html',
  styleUrls: ['./contract-delete.component.scss']
})
export class ContractDeleteComponent implements OnInit {
  contract: IContract;
  contractTypeKey: number;
  constructor(
    public dialogRef: MatDialogRef<ContractDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public contractService: ContractService, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.contract = this.data.contract;
  }
  deleteContract(): void {

    this.contractService.deleteContract(this.contract.id)
      .subscribe(response => {
        if (response === 200) {
          this.dialogRef.close();
          this.openConfirmation();
        }
      });
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  openConfirmation(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '200px';
    dialogConfig.data = { message: 'Contract Deleted successfully', header: 'Delete contract', reload: true };

    const dialogConfirm = this.dialog.open(ConfirmationComponent, dialogConfig);

  }
}
