import { Component, OnInit, InjectionToken, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  availableData: any;
  selectedData: any = [];
  deletedData = [];
  isEditable: boolean;
  header: string;
  onSelectedItemsSave = new EventEmitter();
  onCancelClick = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<MultiselectComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.availableData = this.data.offices;
    this.availableData.sort(function(obj1, obj2) {
      return  Number(obj1.officeInfo.rfgOfficeId) - Number(obj2.officeInfo.rfgOfficeId);
    });
    this.selectedData = this.data.selectedOffices;
    this.selectedData.sort(function(obj1, obj2) {
      return  Number(obj1.officeInfo.rfgOfficeId) - Number(obj2.officeInfo.rfgOfficeId);
    });
    this.isEditable = this.data.editable;
    this.header = this.data.header;
  }

  onOfficeClick(item): void {
    const index: number = this.availableData.indexOf(item);
    if (index !== -1) {
      this.availableData.splice(index, 1);
    }
    this.selectedData.push(item);
  }
  onRemoveClick(item): void {
    const index: number = this.selectedData.indexOf(item);
    var flag = true;
    if (index !== -1) {
      this.selectedData.splice(index, 1);
    }
    if(this.availableData.length != 0) {
    this.availableData.forEach(element => {
      let index: number = this.availableData.indexOf(element);
      let nextIndex: number = index + 1;
      let currentValue = this.availableData[index];
      let nextValue = this.availableData[nextIndex];
      if(index == 0 && item.officeInfo.rfgOfficeId < currentValue.officeInfo.rfgOfficeId) {
        this.availableData.splice(index, 0, item);
        flag = false;
      }
      if(!isNullOrUndefined(nextValue)) {
        if(item.officeInfo.rfgOfficeId > currentValue.officeInfo.rfgOfficeId && item.officeInfo.rfgOfficeId < nextValue.officeInfo.rfgOfficeId) {
          this.availableData.splice(nextIndex, 0, item);
        }
      }
      else if (flag == true) {
        this.availableData.push(item);
      }
    });
  }
  else {
    this.availableData.push(item);
   }
  }
  onSelectAll(): void {
    this.availableData.forEach(x => {
      this.selectedData.push(x);
    });
    this.availableData = [];
  }
  onRemoveAll(): void {
    this.selectedData.forEach(x => {
      if(this.availableData.length != 0) {
        this.availableData.forEach(element => {
        let index: number = this.availableData.indexOf(element);
        let nextIndex: number = index + 1;
        let currentValue = this.availableData[index];
        let nextValue = this.availableData[nextIndex];
        if(index == 0 && x.officeInfo.rfgOfficeId < currentValue.officeInfo.rfgOfficeId) {
          this.availableData.splice(index, 0, x);
        }
        if(!isNullOrUndefined(nextValue)) {
          if(x.officeInfo.rfgOfficeId > currentValue.officeInfo.rfgOfficeId && x.officeInfo.rfgOfficeId < nextValue.officeInfo.rfgOfficeId) {
            this.availableData.splice(nextIndex, 0, x);
          }
        }
        else {
          this.availableData.push(x);
        }
      });
    }
    else {
      this.availableData.push(x);
    }
    });
    this.selectedData = [];
  }
  onSave(): void {
    this.onSelectedItemsSave.emit(this.selectedData);
    this.dialogRef.close();
  }

  onCancel(): void {
    this.onCancelClick.emit();
    this.dialogRef.close();
    if (this.availableData.reload) {
      window.location.reload();
    }
  }
}
