import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';
import { ResolveEnd, Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AdalService } from 'adal-angular4';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';

const applicationName = 'dashFMS-UI';

@Injectable()
export class MonitorService {
  private routerSubscription: Subscription;
  private pageName: string;
  private pageUrl: string;

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableCorsCorrelation: true,
      disableCorrelationHeaders: false,
      enableAutoRouteTracking: true,
      enableRequestHeaderTracking: true
    }
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private adalSrv: AdalService) {

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();

    if (this.adalSrv.userInfo.authenticated) {
      this.setUserId(this.adalSrv.userInfo.userName);
    }

    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof ResolveEnd)
    ).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent) {
        this.pageName = this.getRouteTemplate(event.state.root);
        this.pageUrl = event.urlAfterRedirects;
      }
    });
  }

  setUserId(userId: string, accountId?: string) {
    this.appInsights.setAuthenticatedUserContext(userId, accountId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  startTrackPage() {
    if (!isNullOrUndefined(this.pageName) && !isNullOrEmptyString(this.pageName)) {
      this.appInsights.startTrackPage(this.pageName);
    }
  }

  stopTrackPage() {
    if (!isNullOrUndefined(this.pageName) && !isNullOrEmptyString(this.pageName)) {
      this.appInsights.stopTrackPage(this.pageName, this.pageUrl);
    }
  }

  logPageView(name?: string, uri?: string, properties?: any,
    measurements?: any, duration?: number) {
    this.appInsights.trackPageView({ name, uri });
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    this.appInsights.trackEvent({ name });
  }

  logException(exception: Error, errorCode: any, user: any) {
    if (this.appInsights.context !== undefined) {
      this.appInsights.addTelemetryInitializer(function (envelope) {
        envelope.tags['ErrorCode'] = errorCode;
      });
    }

    this.appInsights.trackException({ id: this.generateNewId(), exception });
  }

  private createRouterSubscription() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.startTrackPage();
      } else if (event instanceof NavigationEnd) {
        this.stopTrackPage();
        this.logPageView();
      }
    });
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
      envelope.tags['ApplicationName'] = applicationName;
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'WEB';
      item.properties['ApplicationName'] = applicationName;
    }
    );
  }

  private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    return properties;
  }

  private generateNewId() {

    const base64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let result = "";
    let random = Math.random() * 1073741824; //5 symbols in base64, almost maxint
    while (random > 0) {
      const char = base64chars.charAt(random % 64);
      result += char;
      random = Math.floor(random / 64);
    }
    return result;
  }
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
