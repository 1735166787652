import { Component, Inject, OnInit, ChangeDetectorRef, NgModule, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAgreementTypeInfo } from '../../shared/models/agreementTypeInfo';
import { CodeValue } from '../../shared/models/codeValue';
import { CodeValueService } from '../../shared/services/codevalue.service';


@Component({
  selector: 'app-export',
  templateUrl: 'export.component.html',
  styleUrls: ['export.component.scss'],
})

export class ExportDialogOverviewComponent implements OnInit {
  selectedFilterValue: number;
  public exportOptions = [
    { value: 1, description: 'Excel' }, {
      value: 2, description: 'PDF'
    }
  ];
  constructor(
    public dialogRef: MatDialogRef<ExportDialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
    this.selectedFilterValue = 1;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

  onExport(): void {
    this.dialogRef.close();
    if (this.selectedFilterValue === 1) {
      this.data.saveAsExcel();
    } else {
      this.data.saveAsPDF();
    }
  }

}
