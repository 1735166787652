import { Injectable } from '@angular/core';

@Injectable()
export class Data {

    public storage: any;
    public showResults: any;
    public alertSearch: any;
    public isBMF: any;
    public isRoyalty: any;
    public isCMF: any;

    public constructor() { }

}
