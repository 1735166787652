import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalErrorHandler } from './global-errorhandler';
import { ErrorsComponent } from './errors.component';
import { ErrorsService } from './errors.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        ErrorsComponent
    ],
    providers: [
        ErrorsService
        , {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        }
    ]
})
export class ErrorsModule { }
