import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavBarService } from './nav-bar.service';
import { AdalService } from 'adal-angular4';
import { ContractTypeDialogOverviewComponent } from './contractTypeDialog/contracttypeDialog.component';
import { StipulationDialogOverviewComponent } from '../stipulation-popup/stipulation-popup.component';
import { AnnualIncreaseTypeDialogComponent } from './annualIncreaseDialog/annualIncreaseDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Data } from '../../shared/providers/data';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { UserService } from '../services/user.service';
import { AuthorizationService } from '../auth/authorization.service';
import { SeasonalityDialogComponent } from './seasonalityDialog/seasonalityDialog.component';
import {AccrualDialogComponent} from './accrualDialog/accrualDialog.component';
const SMALL_WIDTH_BREAKPOINT = 820;
@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('sidenavAnimationIsExpanded', [
      state('true', style({
        width: '202px'
      })),
      state('false', style({
        width: '62px'
      })),
      transition('false <=> true', animate('500ms ease'))
    ])
  ],

  changeDetection: ChangeDetectionStrategy.OnPush

})
export class NavBarComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, {static: true}) snav: MatSidenav;
  mobileQuery: MediaQueryList;
  userName: string;
  isBtnActive = true;
  animating = false;
  private _mobileQueryListener: () => void;
  active = false;

  start() {
    this.animating = true;
    this.tick();
  }

  done() {
    this.animating = false;
  }

  tick() {
    if (this.animating) {
      this.changeDetectorRef.detectChanges();
      requestAnimationFrame(() => this.tick());
    }
  }

  constructor(private adalService: AdalService
    , public changeDetectorRef: ChangeDetectorRef
    , media: MediaMatcher
    , public authorizationService: AuthorizationService
    , public userService: UserService
    , private _eref: ElementRef
    , private router: Router, public nav: NavBarService, public dialog: MatDialog, private data: Data) {
    this.mobileQuery = media.matchMedia(`(min-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (!this.mobileQuery.matches) {
        if (this.snav != null) {
          // this.snav.close();
          this.isBtnActive = false;
        }
      }
    });

    if (this.adalService.userInfo.authenticated) {
      const user = this.authorizationService.getUserInfo();
      if (user !== undefined) {
        this.userName = user.displayName;
      } else {
        this.userName = this.adalService.userInfo.profile.name;
      }
    }
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  logout() {
    this.adalService.logOut();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ContractTypeDialogOverviewComponent, {
      width: '800px',
    });
  }

  openStipulationDialog(): void {
    const dialogRef = this.dialog.open(StipulationDialogOverviewComponent, {
      width: '650px',
    });
  }


  openAnnualIncreaseDialog(billingtype: string): void {
    switch (billingtype) {
      case 'BMF':
        this.data.isBMF = true;
        this.data.isRoyalty = false;
        this.data.isCMF = false;
        break;

      case 'CMF':
        this.data.isBMF = false;
        this.data.isRoyalty = false;
        this.data.isCMF = true;
        break;
        case 'ROYALTY':
        this.data.isBMF = false;
        this.data.isRoyalty = true;
        this.data.isCMF = false;
        break;
    }
    const dialogRef = this.dialog.open(AnnualIncreaseTypeDialogComponent,
      {
        width: '800px',
        disableClose: true
      });
  }
  openSeasonalityDialog(): void {
    const dialogRef = this.dialog.open(SeasonalityDialogComponent,
      {
        width: '800px',
        disableClose: true
      });
  }
  openAccuralDialog(): void {
    const dialogRef = this.dialog.open(AccrualDialogComponent,
      {
        width: '800px',
        disableClose: true,
      });
  }
  get isAuthenticated(): boolean {
    return this.adalService.userInfo.authenticated && (this.authorizationService.permissions !== undefined);
  }
  profileSettings() {
    this.router.navigate(['/profile']);
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.active = false;
    }
  }
  changeState() {
    if (this.active) {
      this.active = false;
    } else {
      this.active = true;
    }
  }
  savedSearches() {
this.router.navigate(['profile/savedSearch']);
  }
}

