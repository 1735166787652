import { Component, Inject, OnInit, ChangeDetectorRef, NgModule, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from '../../../shared/services/global.service';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { StorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { Observable, of, forkJoin } from 'rxjs';
import { CheckableSettings, CheckedState } from '@progress/kendo-angular-treeview';
import { Data } from '../../../shared/providers/data';
import { IAgreementTypeInfo } from '../../../shared/models/agreementTypeInfo';
import { CodeValueService } from '../../../shared/services/codevalue.service';
import { CodeValue } from '../../../shared/models/codeValue';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'app-annualincreasedialog',
  templateUrl: 'annualIncreaseDialog.component.html',
  styleUrls: ['annualIncreaseDialog.component.scss'],
})
export class AnnualIncreaseTypeDialogComponent implements OnInit {

  mmyyyyy: string;
  mmddyyyyy: string;
  royaltyBillingModel: any;
  allRoyaltyBillingModel: any;
  brandSetting: IAgreementTypeInfo[];
  masterFranchiseKey: any;
  allBrand: any[] = [
    {
      key: 1,
      code: 'BHG',
      description: 'Better Homes and Gardens',
    },
    {
      key: 2,
      code: 'C21',
      description: 'Century 21',
    },
    {
      key: 3,
      code: 'CB',
      description: 'Coldwell Banker',
    },
    {
      key: 4,
      code: 'CBC',
      description: 'Coldwell Banker Commercial',
    },
    {
      key: 5,
      code: 'ERA',
      description: 'ERA',
    },
    {
      key: 6,
      code: 'SIR',
      description: 'Sotheby\'s International Realty',
    },
    {
      key: 9,
      code: 'CLB',
      description: 'Climb Real Estate',
    },
    {
      key: 10,
      code: 'COR',
      description: 'Corcoran Group',
    }
  ];
  cmfBrand: any[] = [
    {
      key: 3,
      code: 'CB',
      description: 'Coldwell Banker',
    }];
  public checkedKeys: any[] = [];
  country: CodeValue[] = [];
  brand = [];
  annualIncreaseType: CodeValue[] = [];
  bmfBillingModel: CodeValue[] = [];
  allBMFBillingModel: CodeValue[] = [];
  cmfBillingModel: CodeValue[] = [];
  annualIncreaseTypeForm: FormGroup;
  isLoading = true;
  showAnnualIncreaseError = false;
  countrySelected: string;
  brandSelected: string;
  annualIncreaseSelected: string;
  allAnnualIncreaseSelected = '';
  displayAnnualIncreaseSelected = '';
  billingModelSelected: string;
  billingModelDescription: string;

  ngOnInit() {
    this.getUserBrands();
    this.loadAsync();
  }

  loadAsync() {
    const codeValuesGet = this.codeValueService.getMultipleCodeValues(null);
    const annualIncreaseGet = this.codeValueService.getAnnualIncreaseForFee(252, [4506], null);

    forkJoin([codeValuesGet, annualIncreaseGet]).subscribe(results => {
      const codeValues = results[0];
      const annualIncrease = results[1];

      codeValues.forEach(codeCategory => {
        if (codeCategory['164'] != null) {
          this.allBMFBillingModel = codeCategory['164'];
          //this.bmfBillingModel = this.bmfBillingModel.filter(x => x.key === 3448 || x.key === 3449 || x.key === 4619);
          // this.bmfBillingModel = this.bmfBillingModel.filter(x => x.key === 3448);
        }
        if (codeCategory['237'] != null) {
          this.cmfBillingModel = codeCategory['237'];
          // this.cmfBillingModel = this.cmfBillingModel.filter(x => x.key === 4686);
        }
        if (codeCategory['221'] != null) {
          this.allRoyaltyBillingModel = codeCategory['221'];
         // this.royaltyBillingModel = this.royaltyBillingModel.filter(x => x.key === 4409 || x.key === 4408);
        }
      });

      annualIncrease.forEach(feeType => {
        if (feeType['4506'] != null) {
          this.annualIncreaseType = feeType['4506'];
          if (this.dataValue.isRoyalty) {
            this.annualIncreaseType = this.annualIncreaseType.filter(x => x.key === 4684 || x.key === 4685 || x.key === 4851);
          }
        }
      });

      this.isLoading = false;
    });

  }

  constructor(
    public dialogRef: MatDialogRef<AnnualIncreaseTypeDialogComponent>,
    private codeValueService: CodeValueService, private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any, @Inject(SESSION_STORAGE) private storage: StorageService,
    private fb: FormBuilder, private router: Router, public ref: ChangeDetectorRef, public dataValue: Data) {
    this.createForm();
  }

  createForm() {
    this.annualIncreaseTypeForm = this.fb.group({
      brandKey: ['', Validators.required],
      countryKey: ['', Validators.required],
      bmfBillingModelKey: ['', Validators.required],
    });
  }

  public fetchChildren(node: any): Observable<any[]> {
    return of(node.models);
  }

  public hasChildren(node: any): boolean {
    return node.models && node.models.length > 0;
  }

  public get checkableSettings(): CheckableSettings {
    return {
      checkChildren: true,
      checkParents: true,
      enabled: true,
      mode: 'multiple'
    };
  }

  public isChecked = (dataItem: any, index: string): CheckedState => {
    if (this.containsItem(dataItem)) { return 'checked'; }

    if (this.isIndeterminate(dataItem.models)) { return 'indeterminate'; }

    return 'none';
  }

  private containsItem(item: any): boolean {
    return this.checkedKeys.indexOf(item['key']) > -1;
  }

  private isIndeterminate(items: any[] = []): boolean {
    let idx = 0;
    let item;
    while (item = items[idx]) {
      if (this.isIndeterminate(item.models) || this.containsItem(item)) {
        return true;
      }

      idx += 1;
    }

    return false;
  }
  getUserBrands() {
    if (this.storage.get(`brands`)) {
      const userBrands = this.storage.get(`brands`);
      userBrands.forEach(uBrand => {
        if (this.dataValue.isBMF) {
          this.allBrand.forEach(brand => {
            if (uBrand === brand.code) {
              this.brand.push(brand);
            }
          });
        } else if (this.dataValue.isRoyalty) {
          this.allBrand.forEach(brand => {
            if (uBrand === brand.code) {
              this.brand.push(brand);
            }
          });
        } else {
          this.cmfBrand.forEach(brand => {
            if (uBrand === brand.code) {
              this.brand.push(brand);
            }
          });
        }
      });
    }
  }
  onBrandChange(value: any): void {
    const selectedBrand = this.annualIncreaseTypeForm.get('brandKey').value;
    this.annualIncreaseTypeForm.get('countryKey').setValue('');
    this.annualIncreaseTypeForm.get('bmfBillingModelKey').setValue('');
    let brandCode = '';
    this.brand.forEach(element => {
      if (element.key === selectedBrand) {
        brandCode = element.code;
      }
    });
    this.getCountryList(brandCode);
  }
  onCountryChange(value: any): void {
    const countryKey = this.annualIncreaseTypeForm.get('countryKey').value;
    const countrySetting = this.brandSetting.filter(c => c.country.key === countryKey)[0];
    this.masterFranchiseKey = countrySetting !== undefined && countrySetting !== null ? countrySetting.masterFranchise[0].masterFranchiseKey : null;
    this.royaltyBillingModel = this.masterFranchiseKey === 5 ?
    this.allRoyaltyBillingModel.filter(x => x.key === 4409 || x.key === 4408) :
     this.allRoyaltyBillingModel.filter(x => x.key === 4409);
 
    this.bmfBillingModel = this.masterFranchiseKey === 5 ?
     this.allBMFBillingModel.filter(x => x.key === 3448 || x.key === 3449 || x.key === 4619) :
      this.allBMFBillingModel.filter(x => x.key === 3448 || x.key === 3449);
  }
  getCountryList(brand): void {
    this.country = [];
    this.royaltyBillingModel = [];
    this.bmfBillingModel = [];
    this.globalService.getAgreementConfig(brand)
      .subscribe(data => {
        if (data !== null) {
          this.brandSetting = data;
          data.forEach(element => {
            this.country = [...this.country, element.country];
          });
        }
      });
  }

  minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value != null && c.value.length >= min) {
        return null;
      } else {
        return { 'minLengthArray': { valid: false } };
      }
    };
  }
  onSubmit() {
    if (this.annualIncreaseTypeForm.valid) {
      this.onGo();
    } else {
      this.showAnnualIncreaseError = this.checkedKeys.length > 0 ? false : true;
      this.validateControls(this.annualIncreaseTypeForm);
    }
  }
  validateControls(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.validateControls(abstractControl);
      } else {
        abstractControl.markAsDirty();
        abstractControl.markAsTouched();
      }
    });
  }
  onGo(): void {
    const formModel = this.annualIncreaseTypeForm.value;
    this.showAnnualIncreaseError = this.checkedKeys.length > 0 ? false : true;
    if (this.annualIncreaseTypeForm.valid && this.checkedKeys.length > 0) {
      if (this.dataValue.isBMF) {
        this.bmfBillingModel.forEach(element => {
          if (element.key === formModel.bmfBillingModelKey) {
            this.billingModelSelected = element.code;
            this.billingModelDescription = element.description;
          }
        });
      } else if (this.dataValue.isRoyalty) {
        this.royaltyBillingModel.forEach(element => {
          if (element.key === formModel.bmfBillingModelKey) {
            this.billingModelSelected = element.code;
            this.billingModelDescription = element.description;
          }
        });
      } else {
        this.cmfBillingModel.forEach(element => {
          if (element.key === formModel.bmfBillingModelKey) {
            this.billingModelSelected = element.code;
            this.billingModelDescription = element.description;
          }
        });
      }
      this.country.forEach(element => {
        if (element.key === formModel.countryKey) {
          this.countrySelected = element.code;
        }
      });
      this.brand.forEach(element => {
        if (element.key === formModel.brandKey) {
          this.brandSelected = element.code;
        }
      });

      this.checkedKeys.forEach(key => {
        this.annualIncreaseType.forEach(element => {
          if (element.key === key) {
            this.annualIncreaseSelected = element.description;
          }
        });
        if (this.allAnnualIncreaseSelected === '') {
          this.allAnnualIncreaseSelected = this.annualIncreaseSelected;
        } else {
          this.allAnnualIncreaseSelected = this.allAnnualIncreaseSelected + ',' + this.annualIncreaseSelected;
        }
        if (this.displayAnnualIncreaseSelected === '') {
          this.displayAnnualIncreaseSelected = this.annualIncreaseSelected;
        } else {
          this.displayAnnualIncreaseSelected = this.displayAnnualIncreaseSelected + '-' + this.annualIncreaseSelected;
        }
      });
      const dateObj = new Date();
      if (this.dataValue.isRoyalty) {
        let month = (dateObj.getUTCMonth() + 2).toString();
        const year = dateObj.getUTCFullYear().toString();
        month = (month.length === 1) ? ('0' + month) : month;
        this.mmyyyyy = month + '01' + year;
      } else {
        let month = (dateObj.getUTCMonth() + 1).toString();
        const year = dateObj.getUTCFullYear().toString();
        month = (month.length === 1) ? ('0' + month) : month;
        this.mmyyyyy = month + year;
      }
      const annualIncreaseName = this.mmyyyyy + '_' + this.brandSelected + '_' + this.countrySelected + '_' + this.billingModelDescription + '_' + this.displayAnnualIncreaseSelected;
      this.storage.set(`annualIncreaseType`, formModel);
      this.storage.set(`annualIncreaseName`, annualIncreaseName);
      this.storage.set('countrySelected', this.countrySelected);
      this.storage.set('annualIncreaseSelected', this.allAnnualIncreaseSelected);
      this.storage.set('brandSelected', this.brandSelected);
      this.storage.set('billingModelselected', this.billingModelSelected);
      this.storage.set('billingModelDescription', this.billingModelDescription);
      this.storage.set('annualIncreaseTypeKeys', this.checkedKeys);
      this.storage.set('MFSelected', this.masterFranchiseKey);


      this.router.navigateByUrl('blank').then(() => {
        this.router.navigate(['annualIncreaseType/annualIncreaseTypeHome/add', formModel.brandKey, formModel.countryKey, formModel.bmfBillingModelKey]);
      });
      this.dialogRef.close();
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  onModelChange(): void {

  }
}
