import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ISeasonalityDetail } from './seasonality';

@Injectable()
export class SeasonalityService extends BehaviorSubject<GridDataResult> {

    private seasonalityServiceUrl;
    private handleError: HandleError;
    constructor( private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
        super(null);
        this.handleError = httpErrorHandler.createHandleError('SeasonalityApi');
    this.seasonalityServiceUrl = environment.apiBaseUrl + 'v1/seasonality';
    }
    getSeasonality(year: any, masterFranchiseKeys: any[], isLatest: any): Observable<ISeasonalityDetail[]> {
        let params = new HttpParams().set('year', year);
        params = params.append('isLatest', isLatest);
    if (!isNullOrUndefined(masterFranchiseKeys)) {
        masterFranchiseKeys.forEach(code => {
        params = params.append('masterFranchiseKeys', code);
      });
    }
    return this.http.get<ISeasonalityDetail[]>(this.seasonalityServiceUrl, { params: params })
      .pipe(
        catchError(this.handleError('getSeasonality', []))
      );
        
      }

      saveSeasonality(seasonality: ISeasonalityDetail[]): Observable<any> {
        
        return this.http.post(this.seasonalityServiceUrl, seasonality);
      }

}
