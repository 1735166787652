// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  adalConfig: {
    tenant: 'Realogy.onmicrosoft.com',
    clientId: '001216eb-f2e5-4029-b8ba-2e1c6ccc3042',
    extraQueryParameter: 'domain_hint=realogy.onmicrosoft.com',
    endpoints: {
      'http://localhost/DashMiddleTier2.Api4': 'https://Realogy.onmicrosoft.com/cc2a3515-32f8-42d9-9618-e4b67632cd66'
    },
  },
  appInsights: {
    instrumentationKey: '01b896e2-fc2a-4266-b930-127bc1e31015'
  },
  apiBaseUrl: 'http://localhost/DashMiddleTier2.Api4/',
  bingMapKey: 'AshtLAn_g1VcAci1yqOe9E4wr4Utt5RNdVqM3WSuL3Cpk8FQilWFpp06jfJ1X6te'
};
