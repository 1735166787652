import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IContract } from './contract-template';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { IAgreementTypeInfo } from '../shared/models/agreementTypeInfo';
import { CodeValue } from '../shared/models/codeValue';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ContractService {

  // private contractServiceUrl = environment.apiBaseUrl + 'api/contracts';
  private contractServiceUrl = environment.apiBaseUrl + 'v1/contracttype/getContractTypes/';
  private contractTypeServiceUrl = environment.apiBaseUrl + 'v1/contracttype';
  private codeValueUrl = environment.apiBaseUrl + 'v1/CodeValues';

  private handleError: HandleError;

  requestParams = 'codeCategoryList=';
  headers: Headers;
//options: RequestOptions;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ContractApi');
    this.headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'q=0.8;application/json;q=0.9'
    });
//this.options = new RequestOptions({ headers: this.headers });
  }

 
  getContracts(searchCriteria: any): Observable<IContract[]> {
    return this.http.post<IContract[]>(this.contractServiceUrl, searchCriteria)
      .pipe(
        catchError(this.handleError('getContracts', []))
      );
  }

  getCodeValueByMFKey(codeCategory: number, MFKey: number): Observable<CodeValue[]> {
    return this.http.get<CodeValue[]>(this.codeValueUrl + '/' + codeCategory + '/' + MFKey);
  }
  getApplicableFeeType(codeCategory: Array<number>, brandKey: number): Observable<any> {
    this.requestParams = '';
    codeCategory.forEach(value => {
      this.requestParams = '&codeCategoryList=' + value + this.requestParams;
    });
    this.requestParams = this.requestParams + '&masterFranchiseKey=' + brandKey;
    return this.http.get<CodeValue[]>(this.codeValueUrl + '/?' + this.requestParams);
  }

  saveContract(contractType: Partial<IContract>): Observable<any> {

    return this.http.post(this.contractTypeServiceUrl, contractType);
  }
  deleteContract(contractTypeId: string): Observable<any> {

    return this.http.delete(this.contractTypeServiceUrl + '/' + contractTypeId);
  }
  getContract(contractTypeGuid: string): Observable<IContract> {
    return this.http.get<IContract>(this.contractTypeServiceUrl + '/' + contractTypeGuid);
  }
}
