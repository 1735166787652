import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { AuthorizationService } from './authorization.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private adalSrv: AdalService,
    private userService: UserService,
    private router: Router,
    protected authorizationService: AuthorizationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.adalSrv.userInfo.authenticated) {
      let resource = route.data['resource'];
      let operation = route.data['secureAction'];

      if (route.firstChild) {
        resource = route.firstChild.data['resource'];
        operation = route.firstChild.data['secureAction'];
      }

      if (!isNullOrUndefined(resource) && !isNullOrUndefined(operation)) {
        let hasAccess = false;
        return new Promise((resolve, reject) => {
          if (this.authorizationService.permissions) {
            resource.forEach((set, index) => {
              if (!hasAccess) {
                hasAccess = this.authorizationService.hasPermission(resource[index], operation[index], null);
              }
            });
            if (resource && operation) {
              if (hasAccess === false) {
                this.router.navigate(['unauthorized'], { skipLocationChange: true });
                resolve(false);
              } else {
                resolve(true);
              }
            } else {
              return this.authorizationService.hasPermission(null, null, null);
            }
          } else {
            this.userService.getUserBasicInfo(this.adalSrv.userInfo.userName).subscribe(user => {
              if (user !== null && user !== undefined && user.userKey != null) {
                this.authorizationService.setUserInfo(user);
                this.authorizationService.initializePermissions().then(() => {
                  resource.forEach((set, index) => {
                    if (!hasAccess) {
                      hasAccess = this.authorizationService.hasPermission(resource[index], operation[index], null);
                    }
                  });
                  if (resource && operation) {
                    if (hasAccess === false) {
                      this.router.navigate(['unauthorized'], { skipLocationChange: true });
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  } else {
                    return this.authorizationService.hasPermission(null, null, null);
                  }
                });
              } else {
                resolve(false);
              }

            },
              error => {
                const errorToSend = {
                  message: 'There seems to be a problem with the backend server. Please check and try again later',
                };
                // error when verify so redirect to login page with the return url
                this.router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true });
                resolve(false);
              });
          }
        });

      } else {
        return true;
      }
    } else {
      this.adalSrv.login();
      return false;
    }
  }

  hasRequiredPermission(resource: string, action: string): Observable<boolean> | boolean {
    // If user’s permissions already retrieved from the API
    if (this.authorizationService.permissions) {
      if (resource && action) {
        return this.authorizationService.hasPermission(resource, action, null);
      } else {
        return this.authorizationService.hasPermission(null, null, null);
      }
    } else {
      this.userService.getUserBasicInfo(this.adalSrv.userInfo.userName).subscribe(user => {
        if (user !== null && user !== undefined && user.userKey != null) {
          this.authorizationService.setUserInfo(user);
          this.authorizationService.initializePermissions().then(() => {
            if (resource && action) {
              return this.authorizationService.hasPermission(resource, action, null);
            } else {
              return this.authorizationService.hasPermission(null, null, null);
            }
          });
        }
      }, error => {
        const errorToSend = {
          message: 'There seems to be a problem with the backend server. Please check and try again later',
        };
        // error when verify so redirect to login page with the return url
        this.router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true });
      });
    }
  }
}
