import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IFranchiseAgreement } from '../../billingterms/franchise-agreement';
import { AuthorizationService } from '../../core/auth/authorization.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BillToOfficeComponent } from '../../core/popup/billToOffice/billToOffice.component';
import { ConfirmationComponent } from '../../core/popup/confirmation/confirmation.component';
import { CommonService } from '../services/common.service';




@Component({
  selector: 'app-agreementactions-overlay',
  templateUrl: './agreementactions-overlay.component.html',
  styleUrls: ['./agreementactions-overlay.component.scss']
})
export class AgreementactionsOverlayComponent implements OnInit {
  franchiseAgreement: IFranchiseAgreement;

  constructor(private router: Router, private authorizationService: AuthorizationService,
    public dialog: MatDialog, private commonservice: CommonService
  ) {
  }

  ngOnInit() {
  }

  gotoView() {
    this.router.navigate(['/agreementterms/view/', this.franchiseAgreement.id]);
  }
  gotoEdit() {
    this.router.navigate(['/agreementterms/edit/', this.franchiseAgreement.id]);
  }
  gotoReview() {
    this.router.navigate(['/agreementterms/review/', this.franchiseAgreement.id]);
  }
  gotoAddendum() {
    this.router.navigate(['/addendum', this.franchiseAgreement.id]);
  }
  gotoRenew() {
    this.router.navigate(['/agreementterms/renew', this.franchiseAgreement.id]);
  }
  gotoManageLC() {
    this.router.navigate(['/legalContact/add/', this.franchiseAgreement.id]);
  }
  gotoModifyLC() {
    this.router.navigate(['/legalContact/edit/', this.franchiseAgreement.id]);
  }
  gotoModifyRB() {
    this.router.navigate(['/responsibleBroker/edit/', this.franchiseAgreement.id]);
  }
  openBillToOffice() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.height = '350px';
    let displayMessage;
    dialogConfig.data = { agreementID: this.franchiseAgreement.id };

    const dialogConfirm = this.dialog.open(BillToOfficeComponent, dialogConfig);
    dialogConfirm.componentInstance.onSave.subscribe((data) => {
      const displayHeader = 'Bill To Office';
      if (data) {
        displayMessage = 'Bill To Office saved successfully';
        this.openConfirmation(displayMessage, displayHeader);
      } else {
        displayMessage = 'Error in saving Bill To Office';
        this.billToOfficeError(displayMessage, displayHeader);
      }
    });
  }
  billToOfficeError(displayMessage, displayHeader): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '200px';
    dialogConfig.data = { message: displayMessage, header: displayHeader, reload: false };
    const dialogConfirm = this.dialog.open(ConfirmationComponent, dialogConfig);
  }
  
  openConfirmation(displayMessage, displayHeader): void {
    const headerMessage = displayHeader;
    const message = displayMessage;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { Id: this.franchiseAgreement.id, message: message, header: headerMessage, reload: false, agteementStatus: 'Pending Approval' };
    this.commonservice.serviceData = dialogConfig.data;
    this.router.navigate(['confirmed/']);
  }


  get isManageBillingTerms() {
    if ((this.franchiseAgreement.billingTermsStatus.key === 4620
      || this.franchiseAgreement.billingTermsStatus.key === 4623) && this.franchiseAgreement.status.key !== 931
      && this.authorizationService.hasPermission('BillingTerms', 'Edit', this.franchiseAgreement.company.brand)) {
      return true;
    } else {
      return false;
    }
  }

  get isReviewAndApprove() {
    if (this.franchiseAgreement.billingTermsStatus.key === 4621 && this.franchiseAgreement.status.key !== 931 &&
      this.authorizationService.hasPermission('ApproveBillingTerms', 'Add', this.franchiseAgreement.company.brand)) {
      return true;
    } else { return false; }
  }

  get isRenewal() {
    if (this.franchiseAgreement.status.key === 929 && this.franchiseAgreement.billingTermsStatus.key === 4622 &&
      (this.authorizationService.hasPermission('BillingTerms', 'Edit', this.franchiseAgreement.company.brand) ||
        this.authorizationService.hasPermission('ApproveBillingTerms', 'Add', this.franchiseAgreement.company.brand))) {
      return true;
    } else {
      return false;
    }
  }

  get isBillToOffice() {
    if ((this.franchiseAgreement.isBMFBillToOfficeApplicable || this.franchiseAgreement.isBMFBilledPerOfficeApplicable ||
      this.franchiseAgreement.isCMFBillToOfficeApplicable ||
      this.franchiseAgreement.isMMFBillToOfficeApplicable) && this.franchiseAgreement.status.key === 929 &&
      (this.authorizationService.hasPermission('BillingTerms', 'Edit', this.franchiseAgreement.company.brand) ||
        this.authorizationService.hasPermission('ApproveBillingTerms', 'Add', this.franchiseAgreement.company.brand))) {
      return true;
    } else {
      return false;
    }
  }

  get isManageLegalContact() { 
    if (this.franchiseAgreement.status.key !== 931 && (this.franchiseAgreement.isRBAvailable || this.franchiseAgreement.IsOwner) && !this.franchiseAgreement.isLegalContactAvailable &&
      this.authorizationService.hasPermission('LegalNoticeContact', 'Add', this.franchiseAgreement.company.brand)) {
      return true;
    } else {
      return false;
    }
  }

  get isModifyLegalContact() {
    if (this.franchiseAgreement.status.key !== 931 && (this.franchiseAgreement.isRBAvailable || this.franchiseAgreement.IsOwner) && this.franchiseAgreement.isLegalContactAvailable &&
      this.authorizationService.hasPermission('LegalNoticeContact', 'Edit', this.franchiseAgreement.company.brand)) {
      return true;
    } else {
      return false;
    }
  }

  get isModifyRB() {
    if (this.franchiseAgreement.status.key !== 931 && this.franchiseAgreement.isRBAvailable && this.authorizationService.hasPermission('ResponsibleBroker', 'Edit', this.franchiseAgreement.company.brand)) {
      return true;
    } else {
      return false;
    }
  }
}
