import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  confirmationData: any;
  public isCancelNeeded = false;
  public isYesNeeded = false;
  @Output() onAdd = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.confirmationData = this.data;
    this.isCancelNeeded = this.confirmationData.isCancelNeeded;
    this.isYesNeeded = this.confirmationData.isYesNeeded;
  }

  onYes(): void {
    this.onAdd.emit(true);
    this.dialogRef.close();
  }

  onCancel(): void {
    this.dialogRef.close();
    if (this.confirmationData.reload) {
      window.location.reload();
    }
  }
}
