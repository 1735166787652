import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { BaseComponent } from './base/base.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthGuardService } from './auth/auth.guard';
import { NavBarComponent } from './nav/nav-bar.component';
import { MonitorService } from './services/monitor.service';
import { NavBarService } from './nav/nav-bar.service';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user.service';
import { ContractTypeDialogOverviewComponent } from './nav/contractTypeDialog/contracttypeDialog.component';
import { StipulationDialogOverviewComponent } from './stipulation-popup/stipulation-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractDeleteComponent } from '../contract/delete/contract-delete.component';
import { ConfirmationComponent } from './popup/confirmation/confirmation.component';
import { ExportDialogOverviewComponent } from '../search/export-popup/export.component';
import { MultiselectComponent } from './popup/multiselect/multiselect.component';
import { AuthorizationDataService } from './services/authorization-data.service';
import { AuthorizationService } from './auth/authorization.service';
import { SharedModule } from '../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap';
import { BillToOfficeComponent } from './popup/billToOffice/billToOffice.component';
import { AnnualIncreaseTypeDialogComponent } from './nav/annualIncreaseDialog/annualIncreaseDialog.component';
import { ColumnOptionSelectComponent } from './popup/columnOptionSelect/columnOptionSelect.component';
import { SessionTimeOutComponent } from '../shared/sessionTimeOut/sessionTimeOut.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { SeasonalityDialogComponent } from './nav/seasonalityDialog/seasonalityDialog.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AccrualDialogComponent } from './nav/accrualDialog/accrualDialog.component';
import { EditSeasionalityComponent } from './popup/edit-seasionality/edit-seasionality.component';
import { SeasonalityService } from '../Seasonality/seasonality.service';

@NgModule({
  imports: [
    CommonModule
    , MaterialModule
    , RouterModule
    , BrowserAnimationsModule
    , FormsModule
    , ReactiveFormsModule
    , HttpClientModule
    , BsDropdownModule.forRoot()
    , SharedModule
    , TreeViewModule
    , AngularMultiSelectModule
  ],
  exports: [NavBarComponent],
  declarations: [
    NavBarComponent
    , BaseComponent
    , ConfirmationComponent
    , SessionTimeOutComponent
    , MultiselectComponent
    , ContractTypeDialogOverviewComponent
    , ContractDeleteComponent
    , ExportDialogOverviewComponent
    , StipulationDialogOverviewComponent
    , ColumnOptionSelectComponent
    , BillToOfficeComponent
    , SeasonalityDialogComponent
    , AnnualIncreaseTypeDialogComponent
    , AccrualDialogComponent
    , EditSeasionalityComponent
  ],
  providers: [
    AuthGuardService
    , MonitorService
    , MediaMatcher
    , NavBarService
    , UserService
    , AuthorizationDataService
    , AuthorizationService
    , SeasonalityService
  ],
  bootstrap: [
    NavBarComponent
    , ContractTypeDialogOverviewComponent
    , ContractDeleteComponent
    , MultiselectComponent
    , ExportDialogOverviewComponent
    , ConfirmationComponent
    , SessionTimeOutComponent
    , StipulationDialogOverviewComponent
    , ColumnOptionSelectComponent
    , BillToOfficeComponent
    , SeasonalityDialogComponent
    , AnnualIncreaseTypeDialogComponent
    , AccrualDialogComponent
    , EditSeasionalityComponent
  ]
})

export class CoreModule { }
