import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFranchiseAgreement, IBillToOfficeHistory } from './franchise-agreement';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { environment } from '../../environments/environment';
import { IStipulation } from './stipulationDetail';
import { IAddendum } from '../addendum/addendum';
import { BillingTermsSaveRequest } from './billingtermssaverequest.model';
import { IAgreementAddendum } from '../addendum/agreementAddendum';
import { isUndefined, isNullOrUndefined, isNull } from 'util';
import { ILegalEntityBasicInfo, ILegalEntity } from './legalEntity';
import { LegalEntitySearchCriteria, KeyPersonSearchCriteria } from './legalEntity-searchCriteria';
import { IPersonBasicInfo } from '../person/person';
import { IAgreementKeyPerson } from '../person/keyPerson';


@Injectable()
export class FranchiseAgreementService {
  static isAgreementFromFirm: boolean = false;
  static isRedirectedFrom;
  static gobalSearchResult: any = null;
  static globalSearchCriteria: any = null;
  public isChangeOwner: boolean;
  public existingLEStipKey:any;
  public isAddOwnerAfterChange: boolean;
  private franchiseserviceServiceUrl;
  private franchiseServiceUrl;
  private billingtermsServiceUrl;
  private legalEntitiesServiceUrl;
  private agreementKeyPersonServiceUrl;
  private handleError: HandleError;
  customSubject: ReplaySubject<any>;
  globalSubject: ReplaySubject<any>;

  constructor(
    public http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('FranchiseAgreementApi');
    this.franchiseserviceServiceUrl = environment.apiBaseUrl + 'v1/agreements/';
    this.billingtermsServiceUrl = environment.apiBaseUrl + 'v1/billingterms/';
    this.legalEntitiesServiceUrl = environment.apiBaseUrl + 'v1/LegalEntities/';
    this.agreementKeyPersonServiceUrl = environment.apiBaseUrl + 'v1/agreementKeyPersons/';
    this.franchiseServiceUrl = environment.apiBaseUrl + 'v1/franchisee/';
    this.customSubject = new ReplaySubject<any>();
    this.globalSubject = new ReplaySubject<any>();
  }

  showAgreement(visibility: boolean, redirectedFrom){
    FranchiseAgreementService.isAgreementFromFirm = visibility;
    FranchiseAgreementService.isRedirectedFrom = redirectedFrom;
  }

  getBillToOfficeHistory(id?: string) {
    const apiUrl = `${this.franchiseserviceServiceUrl}${id}/billToOfficeHistory`;
    return this.http.get<IFranchiseAgreement>(apiUrl);
  }

  getFranchiseAgreements(id?: string, addendumid?: string, isBillingTerms = true): Observable<IFranchiseAgreement> {
    let apiUrl;

    if (!isUndefined(addendumid) && addendumid !== null) {
      apiUrl = `${this.franchiseserviceServiceUrl}${id}/${addendumid}?isBillingTerms=${isBillingTerms}`;
      return this.http.get<IFranchiseAgreement>(apiUrl);
    } else {
      apiUrl = `${this.franchiseserviceServiceUrl}${id}?isBillingTerms=${isBillingTerms}`;
      return this.http.get<IFranchiseAgreement>(apiUrl);
    }
  }

  getActiveAgreementOfCompany(companyId: string): Observable<IFranchiseAgreement> {
    const searchCriteria = {
      recordCount: 50,
      includeOnlyPendingAddendemSetup: false,
      includeOnlyPendingApproval: false,
      includeOnlyPendingBillingTermsSetup: false,
      includeOnlyLegalNoticeContact: false,
      companyId: companyId
    };

    return this.http.post<IFranchiseAgreement>(this.franchiseserviceServiceUrl, searchCriteria)
      .pipe(
        map(response => response[0])
      );
  }

  getActiveAgreementOfOffice(officeId: string): Observable<IFranchiseAgreement> {
    const searchCriteria = {
      recordCount: 50,
      includeOnlyPendingAddendemSetup: false,
      includeOnlyPendingApproval: false,
      includeOnlyPendingBillingTermsSetup: false,
      includeOnlyLegalNoticeContact: false,
      officeId: officeId
    };

    return this.http.post<IFranchiseAgreement>(this.franchiseserviceServiceUrl, searchCriteria)
      .pipe(
        map(response => response[0])
      );
  }

  searchAgreements(searchCriteria, isSearch?: boolean): Observable<IFranchiseAgreement[]> {
    //  const params = new HttpParams().set(searchCriteria, this.searchCriteria);
    let getSearchResult = this.http.post<IFranchiseAgreement[]>(this.franchiseserviceServiceUrl, searchCriteria)
      .pipe(
        catchError(this.handleError('getfranchiseagreements', []))
      );
    if (!isNull(isSearch)) {
      FranchiseAgreementService.gobalSearchResult = getSearchResult;
      FranchiseAgreementService.globalSearchCriteria = searchCriteria;
    }
    return getSearchResult;
  }

  searchAgreementResults(): Observable<IFranchiseAgreement[]> {
    return FranchiseAgreementService.gobalSearchResult;
  }

  suggestAgreements(searchterm: string, brands: string[]): Observable<IFranchiseAgreement[]> {
    let params = new HttpParams().set('searchText', searchterm);
    if (!isNullOrUndefined(brands)) {
      brands.forEach(code => {
        params = params.append('brandCodes', code);
      });
    }

    return this.http.get<IFranchiseAgreement[]>(this.franchiseserviceServiceUrl + 'suggest', { params: params })
      .pipe(
        catchError(this.handleError('suggestAgreements', []))
      );
  }

  updateAgreement(id: string, agreement: BillingTermsSaveRequest): Observable<any> {
    return this.http.post(this.billingtermsServiceUrl + id, agreement);
  }


  saveBillToOffice(id: string, agreement: IFranchiseAgreement): Observable<any> {
    return this.http.post(this.franchiseserviceServiceUrl + id + '/billToOffice', agreement);
  }

  approveAgreement(id: string, approvalComment: any): Observable<any> {
    const agreementComment = {
      comment: approvalComment
    };

    return this.http.post(this.billingtermsServiceUrl + id + '/approve', agreementComment);
  }

  rejectAgreement(id: string, rejectComment: any): Observable<any> {
    const agreementComment = {
      comment: rejectComment
    };

    return this.http.post(this.billingtermsServiceUrl + id + '/reject', agreementComment);
  }

  renewAgreement(id: string, renewalDates: any): Observable<any> {
    return this.http.post(this.franchiseserviceServiceUrl + id + '/renew', renewalDates);
  }

  getAgreementAddendums(id: string, isBillingTerms: boolean): Observable<IAddendum[]> {
    const apiUrl = `${this.franchiseserviceServiceUrl}${id}/addendums?sortby=lastupdateon&sortorder=desc&isBillingTerms=${isBillingTerms}`;
    return this.http.get<IAddendum[]>(apiUrl)
      .pipe(
        catchError(this.handleError('getaddendums', []))
      );
  }

  getDraftAgreement(id: string): Observable<IFranchiseAgreement> {
    return this.http.get<IFranchiseAgreement>(`${this.franchiseserviceServiceUrl}draft/${id}`);
  }

  getAgreementByAddendum(id: string, addendumid: string): Observable<IFranchiseAgreement> {
    return this.http.get<IFranchiseAgreement>(this.franchiseserviceServiceUrl + id + '/addendum/' + addendumid);
  }

  getRootLegalEntities(id: string): Observable<ILegalEntityBasicInfo[]> {
    return this.http.get<ILegalEntityBasicInfo[]>(`${this.legalEntitiesServiceUrl}${id}/rootLegalEntities`);
  }
  searchEntityDetails(cacheKey: string, searchterm: string): Observable<ILegalEntityBasicInfo[]> {
    let params = new HttpParams().set('cacheKey', cacheKey);
    params = params.set('searchText', searchterm);
    return this.http.get<ILegalEntityBasicInfo[]>(this.legalEntitiesServiceUrl + 'Suggest', { params: params })
      .pipe(
        catchError(this.handleError('suggestLegalEntity', []))
      );
  }

  validateLegalEntity(searchCriteria:LegalEntitySearchCriteria): Observable<any> {
    return this.http.post(this.legalEntitiesServiceUrl, searchCriteria).pipe(
      catchError(this.handleError('validateLegalEntity', []))
    ); 
  }

   getAgreementOwners(searchCriteria:KeyPersonSearchCriteria): Observable<IPersonBasicInfo[]> {
    return this.http.post<IAgreementKeyPerson[]>(this.agreementKeyPersonServiceUrl+'searchkeyperson', searchCriteria);
  }
  getFranchiseInfo(id: string, versionKey: number): Observable<ILegalEntity> {
    if (versionKey !== null){
    return this.http.get<ILegalEntity>(`${this.legalEntitiesServiceUrl}${id}?legalEntityVRSNKey=${versionKey}`);
    } else{
      return this.http.get<ILegalEntity>(`${this.legalEntitiesServiceUrl}${id}`);
    }
  }

  callComponentMethod(objectParam) {
    this.customSubject.next(objectParam);
  }

  globalComponentMethod(objectParam) {
    this.globalSubject.next(objectParam);
  }
}

