import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { HighlightPipe } from './pipes/highlight.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { GridFilterService } from './services/gridfilter.service';
import { CodeValueService } from './services/codevalue.service';
import { RouterModule } from '@angular/router';
import { AgreementactionsOverlayComponent } from './agreementactions-overlay/agreementactions-overlay.component';
import { SeasonalityFactorOverlayComponent } from './seasonalityfactor-overlay/seasonalityfactor-overlay.component';
import { FirmActionsOverlayComponent } from './firmactions-overlay/firmactions-overlay.component';
import { ExecutedofficesactionsOverlayComponent } from './executedofficesactions-overlay/executedofficesactions-overlay.component';
import { HideIfUnauthorizedDirective } from './hide-if-unauthorized.directive';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InvalidpageComponent } from './invalidpage/invalidpage.component';
import { ContractactionsOverlayComponent } from './contractactions-overlay/contractactions-overlay.component';
import { GlobalService } from './services/global.service';
import { DashBoardFilterService } from '../dashboard/dashboard-filter.service';
import { ClickOutsideDirective } from './click-outside.directive';
import { AnnualIncreaseactionsOverlayComponent } from './annualincreaseactions-overlay/annualincreaseactions-overlay.component';
import { AnnualIncreaseService } from '../annualIncrease/annualIncrease.service';
import { InvalidActionComponent } from './invalidAction/invalidAction.component';
import { YearpickerDirective } from './directives/yearpicker.directive';
import { ReadMoreComponent } from './readmore/readmore.component';
import { PaymentRequestActionsOverlayComponent } from './paymenttrequestactions-overlay/paymentrequestactions-overlay.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    NgSelectModule,
    RouterModule
  ],
  exports: [
    CommonModule
    , AgreementactionsOverlayComponent
    , TranslateModule
    , NgSelectModule
    , MaterialModule
    , ExecutedofficesactionsOverlayComponent
    , AnnualIncreaseactionsOverlayComponent
    , ContractactionsOverlayComponent
    , UnauthorizedComponent
    , HideIfUnauthorizedDirective
    , ClickOutsideDirective
    , HighlightPipe
    , FirmActionsOverlayComponent
    , SeasonalityFactorOverlayComponent
    , YearpickerDirective
    , ReadMoreComponent
    , PaymentRequestActionsOverlayComponent
  ],
  declarations: [
    HighlightPipe
    , AgreementactionsOverlayComponent
    , ExecutedofficesactionsOverlayComponent
    , AnnualIncreaseactionsOverlayComponent
    , ContractactionsOverlayComponent
    , HideIfUnauthorizedDirective
    , ClickOutsideDirective
    , UnauthorizedComponent
    , InvalidpageComponent
    , InvalidActionComponent
    , FirmActionsOverlayComponent
    , SeasonalityFactorOverlayComponent
    , YearpickerDirective
    , ReadMoreComponent
    , PaymentRequestActionsOverlayComponent
  ],
  providers: [GridFilterService, CodeValueService, GlobalService, DashBoardFilterService, AnnualIncreaseService],
})
export class SharedModule { }
