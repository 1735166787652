import { Component, Inject, OnInit, ChangeDetectorRef, NgModule, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContractService } from '../../contract/contract.service';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'app-stipulation-popup',
  templateUrl: 'stipulation-popup.component.html',
  styleUrls: ['stipulation-popup.component.scss'],
})

export class StipulationDialogOverviewComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<StipulationDialogOverviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private contractService: ContractService) {
      }

    onCancel(): void {
        this.dialogRef.close();
      }
    ngOnInit() {
        // this.getCountryList(this.selectedBrand);
       }
}
