import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../../http-error-handler.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { IPreferenceType } from './preference';
import { isNullOrUndefined } from 'util';
import { ISearchValue } from '../../shared/models/searchValue';
import { IGridColumn } from '../../shared/models/gridColumn';

@Injectable()
export class UserService {

  static isAccesible = false;
  private userapplicationpreferencesUrl;
  private userUrl;
  private gridColumnServiceUrl;

  preferenceTypes: Array<IPreferenceType>; // Store the actions for which this user has permission.
  agreementGridColumns: IGridColumn[];
  agreementGridAllColumns: IGridColumn[];
  rebateFirmGridColumns: IGridColumn[];
  rebateFirmGridAllColumns: IGridColumn[];
  incentiveGridColumns: IGridColumn[];
  incentiveFirmGridAllColumns: IGridColumn[];
  paymentRequestGridAllColumns: IGridColumn[];
  constructor(
    public http: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.userapplicationpreferencesUrl = environment.apiBaseUrl + 'v1/userapplicationpreferences/';
    this.userUrl = environment.apiBaseUrl + 'v1/user/';
    this.gridColumnServiceUrl = environment.apiBaseUrl + 'v1/gridcolumns';
  }

  public getUserBasicInfo(loginEmailId: string): Observable<any> {
    return this.http.get(this.userUrl + '?loginEmailId=' + loginEmailId);
  }

  getUserSelectedColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement/user`);
  }
  getUserSelectedRebateFirmColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/rebatefirm/user`);
  }
  getUserSelectedIncentiveFirmColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/incentivefirm/user`);
  }
  getAllColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement`);
  }
  getAllRebateFirmColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/rebateFirm`);
  }
  getAllIncentiveFirmColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/incentivefirm`);
  }
  getDefaultAgreementGridColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement/default`);
  }
  getPaymentRequestGridColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/paymentRequest`);
  }

  public getSelectedFilter(preferenceType: string): Promise<string> {

    return new Promise((resolve, reject) => {
      if (this.preferenceTypes && this.preferenceTypes.find(permision => permision.preferenceType === preferenceType)) {
        const result = this.preferenceTypes.find(permission => {
          return permission.preferenceType === preferenceType;
        });

        resolve(result.preferenceValue);
      } else {

        const apiURL = `${this.userapplicationpreferencesUrl}88`;
        this.http.get<IPreferenceType[]>(apiURL)
          .toPromise()
          .then(res => {
            this.preferenceTypes = res;
            const result = this.preferenceTypes.find(permission => {
              return permission.preferenceType === preferenceType;
            });

            resolve(result.preferenceValue);
          })
          .catch(err => {
            return Promise.reject(err.error || 'Server error');
          });


      }
    });
  }

  setUserPreferences(preference: any): Observable<any> {

    if (this.preferenceTypes) {
      const index = this.preferenceTypes.findIndex(c => c.preferenceType === preference.preferenceType);
      if (!isNullOrUndefined(preference.preferenceValue)) {
        this.preferenceTypes[index] = preference.preferenceValue;
      } else {
        this.preferenceTypes.splice(index, 1);
      }
    }

    return this.http.post(this.userapplicationpreferencesUrl + '88', preference);
  }
  saveUserPreferences(preference: any): Observable<any> {

    return this.http.post(this.userapplicationpreferencesUrl + 'SaveSearchPreferences', preference);
  }

  getUserPreferences(preference: any): Observable<any> {
    return this.http.get<ISearchValue>(this.userapplicationpreferencesUrl + 'GetSearchPreferences');
  }

  SetApplicationAccessedOn(): Observable<any> {
    return this.http.post(this.userUrl, null);
  }



}
