import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorsService } from './errors.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector, private zone: NgZone) {
    }

    handleError(error) {
        const errorsService = this.injector.get(ErrorsService);
        const router = this.injector.get(Router);
        
        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                // Handle offline error
            } else {
                errorsService.log(error).subscribe(errorToSend => this.zone.run(() => router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true })));
            }
        } else if (error instanceof DOMException && error.code === DOMException.INVALID_STATE_ERR) {
            // bing map error
            console.log(error);
        } else {
            errorsService.log(error).subscribe(errorToSend => this.zone.run(() => router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true })));
        }
    }
}
