import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../http-error-handler.service';
import { CodeValue } from '../models/codeValue';
import { IContract } from '../../contract/contract-template';
import { IAgreementTypeInfo } from '../models/agreementTypeInfo';
import { CodeValueService } from './codevalue.service';
import { IOffice } from '../models/office';

@Injectable()
export class GlobalService {

    private globalServiceCountryUrl;
    private globalServiceStateUrl;
    private globalServiceCompanyUrl;
    private globalServiceOfficeUrl;
    private contractTypeServiceUrl = environment.apiBaseUrl + 'v1/contracttype';
private globalServiceMasterFranchiseUrl;
    private handleError: HandleError;
    private accrualServiceUrl;

    constructor(
        public http: HttpClient,
        private codeValueService: CodeValueService,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('GlobalApi');
        this.globalServiceCountryUrl = environment.apiBaseUrl + 'v1/countries';
        this.globalServiceStateUrl = environment.apiBaseUrl + 'v1/states';
        this.globalServiceCompanyUrl = environment.apiBaseUrl + 'v1/companies';
        this.globalServiceOfficeUrl = environment.apiBaseUrl + 'v1/offices';
        this.globalServiceMasterFranchiseUrl = environment.apiBaseUrl + 'v1/masterFranchise';
        this.accrualServiceUrl = environment.apiBaseUrl + 'v1/accruals';
    }
    getCountryList(brandCategories: Array<number>): Observable<any> {
        let requestParams = '';
        for (let i = 0; i < brandCategories.length; i++) {
            if (i === 0) {
                requestParams = 'countryKeyList=' + brandCategories[i];
            } else {
                requestParams = requestParams + '&countryKeyList=' + brandCategories[i];
            }
        }
        return this.http.post(this.globalServiceCountryUrl + '?' + requestParams, null);

    }

    getAgreementConfig(brand: string): Observable<IAgreementTypeInfo[]> {
        return this.http.get<IAgreementTypeInfo[]>(this.contractTypeServiceUrl + '/GetAgreementConfig/' + brand + '/' + 'direct' + '/true');
    }

    getStateList(countryValues: Array<number>): Observable<any> {
        let requestParams = '';
        for (let i = 0; i < countryValues.length; i++) {
            if (i === 0) {
                requestParams = 'countryKeyList=' + countryValues[i];
            } else {
                requestParams = requestParams + '&countryKeyList=' + countryValues[i];
            }
        }
        return this.http.post(this.globalServiceStateUrl + '?' + requestParams, null);
    }
    getCompanyList(brandValues: Array<number>): Observable<any> {
        let requestParams = '';
        for (let i = 0; i < brandValues.length; i++) {
            if (i === 0) {
                requestParams = 'rfgBrandKeys=' + brandValues[i];
            } else {
                requestParams = requestParams + '&rfgBrandKeys=' + brandValues[i];
            }
        }
        return this.http.get(this.globalServiceCompanyUrl + '?' + requestParams);
    }
    getOfficeList(searchCriteria): Observable<any> {
        return this.http.post<IOffice[]>(this.globalServiceOfficeUrl, searchCriteria);

    }

    getMasterFranchise(isRebateEnable: boolean): Observable<any> {
        return this.http.get(this.globalServiceMasterFranchiseUrl + '?isRebateEnable=' + isRebateEnable);
    }
    runAccruals(brandValues:any): Observable<any>{
        return this.http.post(this.accrualServiceUrl, brandValues);
    }
}
