import { Injectable, Inject } from '@angular/core';
import { HttpErrorHandler, HandleError } from '../../http-error-handler.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CodeValue } from '../models/codeValue';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';

@Injectable()
export class CodeValueService {

  private codeValuesUrl;
  private codeValues;
  private anniversaryCodeValues;
  private handleError: HandleError;

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
    public http: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('CodeValuesApi');
    this.codeValuesUrl = environment.apiBaseUrl + 'v1/CodeValues/';
  }

  getCodeValues(codeCategoryKey: number): Observable<CodeValue[]> {
    // const params = new HttpParams().set('codeCategoryKey', codeCategoryKey);
    return this.http.get<CodeValue[]>(this.codeValuesUrl + codeCategoryKey);
    // return this.http.get<CodeValue[]>(this.codeValuesUrl + '/' + codeCategoryKey + '/' + 1);
  }

  getCodeValuesForMasterFranchise(codeCategory: number, masterFranchiseKey: number): Observable<CodeValue[]> {
    return this.http.get<CodeValue[]>(this.codeValuesUrl + '/' + codeCategory + '/' + masterFranchiseKey);
  }

  async  getMultipleCodeValues(masterFranchiseKey: number) {
    let requestParams = '';
    const codeCategories = [3, 22, 47, 48, 90, 137, 158, 163, 164, 172, 221, 232, 233, 235, 237, 240, 241, 245, 246, 248, 59, 260, 261, 262, 263, 264, 272, 273, 275, 279, 282, 283, 11, 305, 304,311,301,310];
    if (this.storage.get(`codevalues_${masterFranchiseKey}`)) {
      return this.storage.get(`codevalues_${masterFranchiseKey}`);
    } else {
      for (let i = 0; i < codeCategories.length; i++) {
        requestParams = requestParams + '&codeCategories=' + codeCategories[i];
      }
      requestParams = requestParams + '&masterFranchiseKey=' + masterFranchiseKey;
      this.codeValues = await this.getCodeValuesRequired(requestParams).toPromise();
      this.storage.set(`codevalues_${masterFranchiseKey}`, this.codeValues);
      return this.codeValues;
    }
  }

  getCodeValuesRequired(requestParams: any) {
    return this.http.get(this.codeValuesUrl + '?' + requestParams);
  }
  getAnnualIncreaseForFee(codeCategory: number, feeTypes: Array<number>, masterFranchiseKey: number): Observable<any> {
    let requestParams = '/annualincreasetypes';
    for (let i = 0; i < feeTypes.length; i++) {
      if (i === 0) {
        requestParams = requestParams + '?feeTypeList=' + feeTypes[i];
      } else {
        requestParams = requestParams + '&feeTypeList=' + feeTypes[i];
      }
    }
    requestParams = requestParams + '&masterFranchiseKey=' + masterFranchiseKey;
    return this.http.get(this.codeValuesUrl + codeCategory + requestParams);
  }
}

