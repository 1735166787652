import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { HttpErrorHandler } from './http-error-handler.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { ErrorsModule } from './core/errors/errors.module';
import { AdalService } from 'adal-angular4';
import { RouteReuseStrategy } from '@angular/router';
import { AARouteReuseStrategy } from '../app/core/providers/aARouteReuseStrategy';
import { Data } from './shared/providers/data';
import { CommonService } from './shared/services/common.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StorageServiceModule } from 'angular-webstorage-service';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import { WINDOW_PROVIDERS } from './core/services/window.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HomeModule,
    SharedModule,
    ErrorsModule,
    HttpClientModule,
    StorageServiceModule,
    NgIdleKeepaliveModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AdalService
    , HttpErrorHandler
    , { provide: LOCALE_ID, useValue: 'en-US' }
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
    , {
      provide: RouteReuseStrategy,
      useClass: AARouteReuseStrategy
    },
    WINDOW_PROVIDERS,
    Data
    , CommonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
