import { Component, OnInit, Inject, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { IFranchiseAgreement,IBillToOfficeHistory } from '../../../billingterms/franchise-agreement';
import { FranchiseAgreementService } from '../../../billingterms/franchise-agreement.service';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';







@Component({
  selector: 'app-billtooffice',
  templateUrl: './billToOffice.component.html',
  styleUrls: ['./billToOffice.component.scss']
})
export class BillToOfficeComponent implements OnInit {
  franchiseAgreement: IFranchiseAgreement;
  billToOffice: IFranchiseAgreement;

  agreementId: any;
  billToOfficeData: any;
  bmfBillToOfficeHistory:IBillToOfficeHistory[];
  cmfBillToOfficeHistory:IBillToOfficeHistory[];
  mmfBillToOfficeHistory:IBillToOfficeHistory[];
  @Output() onSave = new EventEmitter();
  billToOfficeForm: FormGroup;
  offices = [];
  isLoading = true;
  bmfDesignatedOfficeDisplay  = false;
  cmfDesignatedOfficeDisplay  = false;




  constructor(
    public dialogRef: MatDialogRef<BillToOfficeComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    , public franchiseagreementService: FranchiseAgreementService
    , private cdRef: ChangeDetectorRef
    , private fb: FormBuilder
    ) {
      this.createForm();
  }

  createForm() {
    this.billToOfficeForm = this.fb.group({
      mmfBillToOffice: ['', null],
      bmfBillToOffice: ['', null],
      cmfBillToOffice: ['', null],
      bmfBillApplicableTo: [null, null]
    });
  }

  ngOnInit() {
    this.agreementId = this.data.agreementID;
    this.franchiseagreementService.getBillToOfficeHistory(this.agreementId)
    .subscribe(history=>{     
      this.bmfBillToOfficeHistory=history.bmfBillToOfficeHistory;
      this.cmfBillToOfficeHistory=history.cmfBillToOfficeHistory;
      this.mmfBillToOfficeHistory=history.mmfBillToOfficeHistory;  
    });

    this.franchiseagreementService.getFranchiseAgreements(this.agreementId)
    .subscribe(agreement => {
      this.franchiseAgreement = agreement;
      this.franchiseAgreement.offices.forEach(office => {
        office.displayAddress = office.rfgOfficeId + '-' + office.officeAddress.addressLine1 + ', '
        + office.officeAddress.city + ', ' + office.officeAddress.stateCode;
        if (office.status === 'Open') {
          this.offices.push(office);
        }
      });

      if (this.franchiseAgreement.mmfBilledToOffice) {
        this.billToOfficeForm.get('mmfBillToOffice').setValue(this.franchiseAgreement.mmfBilledToOffice.id);
        this.billToOfficeForm.get('mmfBillToOffice').setValidators(Validators.required);
        this.billToOfficeForm.get('mmfBillToOffice').updateValueAndValidity();
      }

      if (this.franchiseAgreement.isBMFBillToOfficeApplicable && this.franchiseAgreement.isBMFBilledPerOfficeApplicable && this.franchiseAgreement.bmfBilledToOffice) {
        this.bmfDesignatedOfficeDisplay = true;
        this.billToOfficeForm.get('bmfBillToOffice').setValue(this.franchiseAgreement.bmfBilledToOffice.id);
        this.billToOfficeForm.get('bmfBillToOffice').setValidators(Validators.required);
        this.billToOfficeForm.get('bmfBillToOffice').updateValueAndValidity();
        this.billToOfficeForm.get('bmfBillApplicableTo').setValue('2');
      }

      if (this.franchiseAgreement.isBMFBillToOfficeApplicable && !this.franchiseAgreement.isBMFBilledPerOfficeApplicable) {
        this.bmfDesignatedOfficeDisplay = true;
        if (this.franchiseAgreement.bmfBilledToOffice) {
          this.billToOfficeForm.get('bmfBillToOffice').setValue(this.franchiseAgreement.bmfBilledToOffice.id);
          this.billToOfficeForm.get('bmfBillToOffice').setValidators(Validators.required);
          this.billToOfficeForm.get('bmfBillToOffice').updateValueAndValidity();
        }
      }

      if (this.franchiseAgreement.isBMFBilledPerOffice) {
        this.billToOfficeForm.get('bmfBillApplicableTo').setValue('1');
      }

      if (this.franchiseAgreement.isCMFBillToOfficeApplicable && this.franchiseAgreement.cmfBilledToOffice) {
        this.cmfDesignatedOfficeDisplay = true;
        this.billToOfficeForm.get('cmfBillToOffice').setValue(this.franchiseAgreement.cmfBilledToOffice.id);
        this.billToOfficeForm.get('cmfBillToOffice').setValidators(Validators.required);
        this.billToOfficeForm.get('cmfBillToOffice').updateValueAndValidity();
      }

      if (this.franchiseAgreement.isCMFBillToOfficeApplicable) {
        this.cmfDesignatedOfficeDisplay = true;
        if (this.franchiseAgreement.cmfBilledToOffice) {
          this.billToOfficeForm.get('cmfBillToOffice').setValue(this.franchiseAgreement.cmfBilledToOffice.id);
          this.billToOfficeForm.get('cmfBillToOffice').setValidators(Validators.required);
          this.billToOfficeForm.get('cmfBillToOffice').updateValueAndValidity();
        }
      }

      this.isLoading = false;
    });

    this.cdRef.detectChanges();
  }

  Save() {
     if (this.billToOfficeForm.valid) {
     this.billToOffice = this.prepareBillToOffice();
     this.franchiseagreementService.saveBillToOffice(this.franchiseAgreement.id, this.billToOffice)
     .subscribe(response => {
     this.onSave.emit(true);
     this.dialogRef.close();
  },
    error => {
      this.onSave.emit(false);
      this.dialogRef.close();
    });
   } else {
      this.validateControls(this.billToOfficeForm);
          }
}

  prepareBillToOffice(): IFranchiseAgreement {
      const formModel = this.billToOfficeForm.value;
      const saveBillToOffice: IFranchiseAgreement = {
      bmfBilledToOffice : { id : formModel.bmfBillToOffice },
      cmfBilledToOffice : { id : formModel.cmfBillToOffice },
      mmfBilledToOffice : { id : formModel.mmfBillToOffice },
      isBMFBilledPerOffice : formModel.bmfBillApplicableTo === '1' ? true  : false,
    };
    return saveBillToOffice;
  }

  Cancel() {
    this.dialogRef.close();
  }

  handleBMFOfficeChange() {
    const billToOfficeValue = this.billToOfficeForm.get('bmfBillApplicableTo').value;
    if (billToOfficeValue === '1') {
    this.bmfDesignatedOfficeDisplay = false;
    this.billToOfficeForm.get('bmfBillToOffice').setValue('');
    this.billToOfficeForm.get('bmfBillToOffice').setValidators(null);
    this.billToOfficeForm.get('bmfBillToOffice').updateValueAndValidity();
    this.billToOfficeForm.get('bmfBillToOffice').disable();
    } else {
      this.bmfDesignatedOfficeDisplay = true;
      this.billToOfficeForm.get('bmfBillToOffice').setValue('');
      this.billToOfficeForm.get('bmfBillToOffice').setValidators([Validators.required]);
      this.billToOfficeForm.get('bmfBillToOffice').updateValueAndValidity();
      this.billToOfficeForm.get('bmfBillToOffice').enable();
    }
  }

  validateControls(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.validateControls(abstractControl);
      } else {
        abstractControl.markAsDirty();
        abstractControl.markAsTouched();
      }
    });
  }
}
