import { Injectable, EventEmitter, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IFranchiseAgreement } from '../billingterms/franchise-agreement';
import { State, process } from '@progress/kendo-data-query';
import { IContract } from '../contract/contract-template';
import { IAgreementCriteria } from '../billingterms/agreementCriteria';
import { map } from 'rxjs/operators';
import { IGridColumn } from '../shared/models/gridColumn';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { IannualIncreaseDetail } from '../annualIncrease/annualIncreaseDetail';
import { IseasonalityFactorDetails } from '../dashboard/seasonalityFactorDetails';
import { Router } from '@angular/router';
import { ErrorsService } from '../core/errors/errors.service';
import { IFirmSearchResult, IFirmSearchCriteria } from '../payables/firm';

@Injectable()
export class DashBoardFilterService extends BehaviorSubject<GridDataResult> {
  dataUpdated = new EventEmitter<boolean>();
  private franchiseserviceServiceUrl;
  private contractServiceUrl;
  private gridColumnServiceUrl;
  private annualIncreaseServiceUrl;
  private firmServiceUrl;
  private seasonalityServiceUrl;
  rows = [];
  public searchCriteria: IAgreementCriteria;
  private firmSearchCriteria: IFirmSearchCriteria
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService
    , private http: HttpClient
    , private router: Router
    , public errorService: ErrorsService) {
    super(null);
    this.franchiseserviceServiceUrl = environment.apiBaseUrl + 'v1/agreements/';
    this.contractServiceUrl = environment.apiBaseUrl + 'v1/contracttype/';
    this.gridColumnServiceUrl = environment.apiBaseUrl + 'v1/gridcolumns';
    this.annualIncreaseServiceUrl = environment.apiBaseUrl + 'v1/annualIncrease/';
    this.firmServiceUrl = environment.apiBaseUrl + 'v1/firms';
    this.seasonalityServiceUrl = environment.apiBaseUrl + 'v1/seasonality/';

    this.searchCriteria = {
      recordCount: 50,
      sortBy: 'lastupdateon',
      sortOrder: 'desc',
      includeOnlyPendingAddendemSetup: false,
      includeOnlyPendingApproval: false,
      includeOnlyPendingBillingTermsSetup: false,
      includeOnlyLegalNoticeContact: false,
      status: null
    };
    this.firmSearchCriteria = {
      recordCount: 50,
      sortBy: 'lastupdateon',
      sortOrder: 'desc',
      types: [{ description: 'RebatePrograms' }],
      isActive: true,
    };
  }

  public getFilteredAgreements(state: any, searchCriteria: any): void {
    this.searchCriteria = searchCriteria;
    this.getAgreements(state, this.searchCriteria)
      .then(x => {
        super.next(x);
        this.dataUpdated.emit(true);
      });
  }

  queryAll(filterType: any, st?: any): Promise<GridDataResult> {
    const state = {
      skip: 0,
      take: 50
    };

    if (filterType === 'agreements') {
      return this.getAgreements(state, this.searchCriteria);
    } else if (filterType === 'contracts') {
      return this.getContracts(state, this.searchCriteria);
    } else if (filterType === 'rebatefirm') {

      return this.getActiveRebateFirms(state, this.firmSearchCriteria);
    }
  }
  queryAllseasonality(filterType: any, st?: any): Observable<IseasonalityFactorDetails[]> {

    const state = {
      skip: 0,
      take: 50
    };
    if (filterType === 'Seasonality') {
      const criteria = {
        recordCount: 50,
        sortBy: 'lastupdateon',
        sortOrder: 'desc',
        type: { key: 5173 },
        isActive: true,
      };
      return this.getseasonalityfactorDetails(state, criteria);
    }

  }

  public getAnnualIncreaseBatchs(state: State, searchCriteria: any): void {
    this.searchCriteria = searchCriteria;
    this.getAnnualIncreaseBatchDetails(state, this.searchCriteria)
      .then(x => {
        super.next(x);
        this.dataUpdated.emit(true);
      });
  }



  public getseasonalityfactorDetails(state: State, searchCriteria: any): Observable<IseasonalityFactorDetails[]> {
    return this.http.get<IseasonalityFactorDetails[]>(this.seasonalityServiceUrl);
  }


  public getRecentContracts(state: State, searchCriteria: any): void {
    this.searchCriteria = searchCriteria;
    this.getContracts(state, this.searchCriteria)
      .then(x => {
        super.next(x);
        this.dataUpdated.emit(true);
      });
  }

  public getFirms(state: State, searchCriteria: any): void {
    this.firmSearchCriteria = searchCriteria;
    this.getActiveRebateFirms(state, this.firmSearchCriteria)
      .then(x => {
        super.next(x);
        this.dataUpdated.emit(true);
      });

  }
  private async getAnnualIncreaseBatchDetails(state: State, searchCriteria: any): Promise<GridDataResult> {
    this.rows = await this.http.post<IannualIncreaseDetail[]>(this.annualIncreaseServiceUrl + 'annualincreasebatch', searchCriteria)
      .pipe(map(this.transformBatchData)).toPromise();
    return process(this.rows, state);
  }







  private async getContracts(state: State, searchCriteria: any): Promise<GridDataResult> {
    try {
      this.rows = await this.http.post<IContract[]>(this.contractServiceUrl + 'getContractTypes/', searchCriteria)
        .pipe(
          map(this.transformContractData)
        )
        .toPromise();
      return process(this.rows, state);
    } catch (error) {
      this.errorService.log(error, 'Error occured while retrieving data').subscribe(errorToSend => {
        this.router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true });
      });
    }
  }

  async  getContractsSection(state: State, searchCriteria: any): Promise<any> {
    if (searchCriteria.brands === null || searchCriteria.brands.length <= 0) {
      if (this.storage.get(`contracttypes_null`)) {
        return this.storage.get(`contracttypes_null`);
      } else {
        this.rows = await this.http.post<IContract[]>(this.contractServiceUrl + 'getContractTypes/', searchCriteria)
          .pipe(
            map(this.transformContractData)
          )
          .toPromise();
        this.storage.set(`contracttypes_null`, this.rows);
        return this.rows;
      }
    } else {
      this.rows = await this.http.post<IContract[]>(this.contractServiceUrl + 'getContractTypes/', searchCriteria)
        .pipe(
          map(this.transformContractData)
        )
        .toPromise();
      this.storage.set(`contracttypes_brands`, this.rows);
      return this.rows;
    }
  }
  private async getAgreements(state: State, searchCriteria: any): Promise<GridDataResult> {
    try {
      this.rows = await this.http.post<IFranchiseAgreement[]>(this.franchiseserviceServiceUrl, searchCriteria)
        .pipe(
          map(this.transformAgreementData)
        )
        .toPromise();
      return process(this.rows, state);
    } catch (error) {
      this.errorService.log(error, 'Error occured while retrieving data').subscribe(errorToSend => {
        this.router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true });
      });
    }
  }
  private async getActiveRebateFirms(state: State, searchCriteria: any): Promise<GridDataResult> {
    try {
      this.rows = await this.http.post<IFirmSearchResult[]>(this.firmServiceUrl, searchCriteria)
        .pipe(
          map(this.transformFirmData)
        )
        .toPromise();
      return process(this.rows, state);
    } catch (error) {
      this.errorService.log(error, 'Error occured while retrieving data').subscribe(errorToSend => {
        this.router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true });
      });
    }
  }
  private transformFirmData(firms: IFirmSearchResult[]): IFirmSearchResult[] {
    firms.forEach(item => {
      if (item.activeSince != null) {
        item.activeSince = new Date(item.activeSince);
      }
      if (item.created != null) {
        item.created.on = new Date(item.created.on);
      } if (item.lastUpdated != null) {
        item.lastUpdated.on = new Date(item.lastUpdated.on);
      }
      item.comboFirm = item.isComboFirm ? 'Yes' : 'No';
      if (item.deactivatedOn != null) {
        item.deactivatedOn = new Date(item.deactivatedOn);
      }
      if (item.status == null && (item.deactivatedOn == null || item.deactivatedOn > new Date())) {
        item.status = { description: 'Active' };
      } else if (item.status == null && item.deactivatedOn != null && item.deactivatedOn <= new Date()) {
        item.status = { description: 'Deactive' };
      }
    });

    return firms;
  }

  private transformAgreementData(franchiseAgreements: IFranchiseAgreement[]): IFranchiseAgreement[] {
    franchiseAgreements.forEach(item => {

      if (item.effectiveFrom != null) {
        item.effectiveFrom = new Date(item.effectiveFrom);
      }

      item.billingTermsStatusOnUi = item.billingTermsStatus.description;

      if (item.versionDate != null) {
        const d = new Date(item.versionDate);
        item.contractTypeNameDate = item.contractType + '';
      }

      if (item.billingTermsStatus.description === 'Rejected') {
        item.billingTermsStatusOnUi = 'Pending Agreement Terms Setup';
      }

      if (item.effectiveTo != null) {
        item.effectiveTo = new Date(item.effectiveTo);
      }


      if (item.created != null) {
        item.created.on = new Date(item.created.on);
      }

      if (item.deactivatedOn != null) {
        item.deactivatedOn = new Date(item.deactivatedOn);
      }

      if (item.termStartDate != null) {
        item.termStartDate = new Date(item.termStartDate);
      }

      if (item.lastUpdated != null) {
        item.lastUpdated.on = new Date(item.lastUpdated.on);
      }


      if (item.isAgreementRenewed === true) {
        item.isAgreementRenewed = 'Yes';
      } else {
        item.isAgreementRenewed = 'No';
      }
    });

    return franchiseAgreements;
  }

  private transformBatchData(batchs: IannualIncreaseDetail[]): IannualIncreaseDetail[] {
    batchs.forEach(item => {
      if (item.lastUpdate.on != null) {
        item.lastUpdate.on = new Date(item.lastUpdate.on);
      }

      if (item.status.key === 4884) {
        item.status.description = 'Pending Approval';
      } else if (item.status.key === 4885) {
        item.status.description = 'Rejected';
      }

      if (item.feeEffectiveFrom != null) {

        item.feeEffectiveFrom = (new Date(item.feeEffectiveFrom).getMonth() + 1) + '/' + new Date(item.feeEffectiveFrom).getDate() + '/' + new Date(item.feeEffectiveFrom).getFullYear();
      }
    });
    return batchs;
  }


  private transformContractData(contracts: IContract[]): IContract[] {
    contracts.forEach(item => {
      item.versionDate = new Date(item.versionDate).toDateString();
      item.contractVersionDate = new Date(item.versionDate);
      item.contractLength = item.contractDurationYears + ' Years';
      if (item.lastUpdated != null) {
        item.lastUpdated.on = new Date(item.lastUpdated.on);
      }

      if (item.created != null) {
        item.created.on = new Date(item.created.on);
      }
    });
    return contracts;
  }

  getAllColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement`);
  }

  getDefaultGridColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement/default`);
  }

  getUserGridColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/agreement/user`);
  }

  saveUserGridColumns(userGridColoumns: IGridColumn[]): Observable<any> {
    const url = `${this.gridColumnServiceUrl}/agreement`;
    return this.http.post(url, userGridColoumns);
  }

  getContractTypeColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/contractType`);
  }

  getDefaultContractTypetColoumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/contractType/default`);
  }

  getContractTypeUserGridColoumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/contractType/user`);
  }

  saveContractTypeUserGridColoumns(userGridColoumns: IGridColumn[]): Observable<any> {
    const url = `${this.gridColumnServiceUrl}/contractType`;
    return this.http.post(url, userGridColoumns);
  }

  getRebateFirmColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/rebatefirm`);
  }

  getDefaultRebateFirmtColoumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/rebatefirm/default`);
  }

  getRebateFirmUserGridColoumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/rebatefirm/user`);
  }

  saveFirmUserGridColoumns(userGridColoumns: IGridColumn[], firmType: string): Observable<any> {
    const url = `${this.gridColumnServiceUrl}/${firmType}`;
    return this.http.post(url, userGridColoumns);
  }
}
