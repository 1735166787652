import { Injectable, Injector, NgZone } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Event, NavigationError } from '@angular/router';

import { of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MonitorService } from '../services/monitor.service';
import { AdalService } from 'adal-angular4';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ErrorsService {

    constructor(
        private injector: Injector,
        private router: Router,
        private zone: NgZone
    ) {
        // Subscribe to the NavigationError
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationError) {
                const errorToSend = this.addContextInfo(event.error);
                this.zone.run(() => router.navigate(['/error'], { queryParams: errorToSend, skipLocationChange: true }));
            }
        });
    }

    log(error, userFriendlyMessage = null) {
        // Log the error to the console
        const errorToSend = this.addContextInfo(error, userFriendlyMessage);
        const monitorService = this.injector.get(MonitorService);
        if (error instanceof HttpErrorResponse) {
            monitorService.logException(new Error(errorToSend.message), errorToSend.id, errorToSend.user);
        } else {
            monitorService.logException(error, errorToSend.id, errorToSend.user);
        }

        return of(errorToSend);
    }

    addContextInfo(error, userFriendlyMessage= null) {
        const adalService = this.injector.get(AdalService);
        const name = error.name || null;
        const appId = environment.adalConfig.clientId;
        const user = adalService.userInfo.userName;
        const time = new Date();
        const time1 = new Date().getTime();
        const id = `${appId}-${time1}`;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;

        let message = error instanceof HttpErrorResponse ? error.error.message : error.toString();
        if (isNullOrUndefined(message) && status === 400) {
            message = `${error.statusText} for ${error.url}`;
        }

        const displayMessage = !isNullOrUndefined(userFriendlyMessage) ? userFriendlyMessage : message;
        const errorWithContext = { name, appId, time, id, url, status, message, displayMessage, user };
        return errorWithContext;
    }
}
