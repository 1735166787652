import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../core/auth/authorization.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { IseasonalityFactorDetails } from '../../dashboard/seasonalityFactorDetails';
import { EditSeasionalityComponent } from '../../../app/core/popup/edit-seasionality/edit-seasionality.component';
import { ConfirmationComponent } from '../../../app/core/popup/confirmation/confirmation.component';


@Component({
  selector: 'app-SeasonalityFactor-overlay',
  templateUrl: './seasonalityfactor-overlay.component.html',
  styleUrls: ['./seasonalityfactor-overlay.component.scss']
})
export class SeasonalityFactorOverlayComponent implements OnInit {
  seasonality: IseasonalityFactorDetails;

  constructor(private router: Router, private authorizationService: AuthorizationService,
    public dialog: MatDialog, private commonservice: CommonService
  ) {
  }

  ngOnInit() {
  }

  get showEditSeasonality() {
    return this.authorizationService.hasPermission('Seasonality', 'Add', this.seasonality.rfgBrand.code);
  }

  openEditSeasionality() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '610px';
    dialogConfig.height = 'auto';
    let displayMessage;
    dialogConfig.data = { seasionality: this.seasonality };

    const dialogConfirm = this.dialog.open(EditSeasionalityComponent, dialogConfig);
    dialogConfirm.componentInstance.onSave.subscribe((data) => {
      const displayHeader = 'Seasonality Factor';
      if (data) {
        displayMessage = 'Seasonality Factor Updated Successfully';
        this.openConfirmation(displayMessage, displayHeader);
      } else {
        displayMessage = 'Error in saving Seasonality Factor';
        this.seasionalityFactorError(displayMessage, displayHeader);
      }
    });
  }

  openConfirmation(displayMessage, displayHeader): void {
    const headerMessage = displayHeader;
    const message = displayMessage;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      Id: this.seasonality.year, message: message, header: headerMessage, reload: false, isRebateFirm: false,
      pageSource: 'seasonality'
    };
    this.commonservice.serviceData = dialogConfig.data;
    this.router.navigate(['confirmed/']);
  }

  seasionalityFactorError(displayMessage, displayHeader): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '200px';
    dialogConfig.data = { message: displayMessage, header: displayHeader, reload: false };
    const dialogConfirm = this.dialog.open(ConfirmationComponent, dialogConfig);
  }
}
