import { Injectable, Inject } from '@angular/core';
import { AuthorizationDataService } from '../services/authorization-data.service';
import { IPermission } from '../services/permission';
import { IUser } from '../../shared/models/user';
import { StorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { isNullOrUndefined } from 'util';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthorizationService {
      
      applicationAccessOnSet: any;
      isAccessedOnSave: any;
      permissions: Array<IPermission>; // Store the actions for which this user has permission
      userInfo: IUser;

      constructor(@Inject(SESSION_STORAGE) private storage: StorageService, private authorizationDataService: AuthorizationDataService, private userService: UserService) { }
      hasPermission(resource: string, action: string, brand: string): boolean {
            if (this.permissions && this.permissions.find(permission => {
                  return permission.resource === resource && permission.action === action && (permission.brand === brand || brand === undefined || brand === null);
            })) {
                  return true;
            } else {
                  return false;
            }
      }

      // This method is called once and a list of permissions is stored in the permissions property
      lastLoggedIn() {
      const isAccessedOnSave = this.storage.get(`isAccessedOnSave`);
               if (isNullOrUndefined( isAccessedOnSave ) && !UserService.isAccesible) {
                  UserService.isAccesible = true;
        this.userService.SetApplicationAccessedOn().subscribe(applicationAccessOnSet => {
        this.applicationAccessOnSet = applicationAccessOnSet;
        this.storage.set(`isAccessedOnSave`, true);
        });
      }
      }

      initializePermissions() {

            const identityId = this.userInfo.userKey;
            return new Promise((resolve, reject) => {
                  // Call API to retrieve the list of actions this user is permitted to perform.
                  // In this case, the method returns a Promise, but it could have been implemented as an Observable
                  this.authorizationDataService.getUserPermissions(identityId).toPromise()
                        .then(permissions => {
                              this.permissions = permissions;
                              const source = [], brandPermissions = [];
                              for (let i = 0; i < this.permissions.length; i++) {
                                    if (source[this.permissions[i].brand]) {
                                          continue;
                                    }
                                    source[this.permissions[i].brand] = true;
                                    brandPermissions.push(this.permissions[i].brand);
                              }
                              this.storage.set(`brands`, brandPermissions);
                              resolve();
                        })
                        .catch((e) => {
                              reject(e);
                        });
            });
      }

      setUserInfo(userInfo: IUser) {
            this.userInfo = userInfo;
      }

      getUserInfo() {
            return this.userInfo;
      }
}
