import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IContract } from '../../contract/contract-template';
import { ContractDeleteComponent } from '../../contract/delete/contract-delete.component';

@Component({
  selector: 'app-contractactions-overlay',
  templateUrl: './contractactions-overlay.component.html',
  styleUrls: ['./contractactions-overlay.component.scss']
})
export class ContractactionsOverlayComponent implements OnInit {
  contract: IContract;

  constructor(private router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  gotoView() {
    this.router.navigate(['/contract/view/' , this.contract.id]);
  }
  gotoEdit() {
    this.router.navigate(['/contract/edit/', 'BHG', 'US', 'USD']);
  }
  gotoCopy() {
    this.router.navigate(['/contract/copy/' ]);
  }
  openDelete(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = '350px';
    dialogConfig.data = {contract: this.contract};

    const dialogRef = this.dialog.open(ContractDeleteComponent, dialogConfig);

  }
}
