import { Injectable } from '@angular/core';

const gridFilters: GridFilter[] = [
  { description: 'Recently Updated Agreements', value: '1', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Recently Updated Agreements', value: '1', entityType: 'dashboard', resource: 'FranchiseAgreement' },
  { description: 'Recently Rejected Agreements', value: '2', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Recently Approved Agreements', value: '3', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Recently Executed Agreements', value: '4', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Recently Executed Agreements', value: '4', entityType: 'dashboard', resource: 'FranchiseAgreement' },
  // { description: 'Recent Contract Templates', value: '5', entityType: 'tools' },
  // { description: 'Recent Stipulations', value: '6', entityType: 'tools' },
  // { description: 'Active Contract Templates', value: '7', entityType: 'tools' },
  // { description: 'Billing Stipulations', value: '8', entityType: 'tools' },
  { description: 'Recently Active Agreements', value: '9', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Recently Active Agreements', value: '9', entityType: 'dashboard', resource: 'FranchiseAgreement' },
  { description: 'Agreements Expiring in next 30 days', value: '10', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Agreements Expiring in next 30 days', value: '10', entityType: 'dashboard', resource: 'FranchiseAgreement' },
  { description: 'Agreements with changes in progress', value: '11', entityType: 'agreementterms', resource: 'FranchiseAgreement' },
  { description: 'Annual Increase History', value: '12', entityType: 'dashboard', resource: 'AnnualIncrease' },
  { description: 'Annual Increase History', value: '12', entityType: 'tools', resource: 'AnnualIncrease' },

  { description: 'Recently Active Rebate Firms', value: '13', entityType: 'dashboard', resource: 'Payables' },
  // { description: 'Rebate Firm', value: '13', entityType: 'payables' },
  { description: 'Recently Active Rebate Firms', value: '1', entityType: 'payables', resource: 'Payables' },
  { description: 'Recently Active Incentive Firms', value: '2', entityType: 'payables', resource: 'Payables' },
  { description: 'Seasonality Factor', value: '3', entityType: 'payables', resource: 'Payables' },
  { description: 'Rebate Firms Expiring in Next 18 Months', value: '4', entityType: 'payables', resource: 'Payables' },
  { description: 'Incentive Firms Expiring in Next 18 Months', value: '5', entityType: 'payables', resource: 'Payables' },


  { description: 'Seasonality Factor', value: '14', entityType: 'dashboard', resource: 'Payables' },
  { description: 'Recently Active Incentive Firms', value: '15', entityType: 'dashboard', resource: 'Payables' },
  { description: 'Recently Deactivated Rebate Firms', value: '6', entityType: 'payables', resource: 'Payables' },
  { description: 'Recently Deactivated Incentive Firms', value: '7', entityType: 'payables', resource: 'Payables' }

];


@Injectable()
export class GridFilterService {
  getFilterList(entityType: string): GridFilter[] {
    return gridFilters.filter(option => option.entityType === entityType);
  }
}

export interface GridFilter {
  value: string;
  description: string;
  entityType: string;
  resource: string;
}

