import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IPermission } from './permission';

@Injectable()
export class AuthorizationDataService {
  private authorizationServiceUrl;

  constructor(public http: HttpClient) {
    this.authorizationServiceUrl = environment.apiBaseUrl + 'v1/authorizations/';
  }

  getUserPermissions(identityId: number): Observable<IPermission[]> {
    return this.http.get<IPermission[]>(`${this.authorizationServiceUrl}fine/${identityId}?applicationId=88&orgLevel=1`);
  }

}
