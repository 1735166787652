import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../core/auth/authorization.service';
import { IannualIncreaseDetail } from '../../annualIncrease/annualIncreaseDetail';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AnnualIncreaseService } from '../../annualIncrease/annualIncrease.service';
import { ConfirmationComponent } from '../../core/popup/confirmation/confirmation.component';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-annualincreaseactions-overlay',
  templateUrl: './annualincreaseactions-overlay.component.html',
  styleUrls: ['./annualincreaseactions-overlay.component.scss']
})
export class AnnualIncreaseactionsOverlayComponent implements OnInit {
  annualIncreaseBatch: IannualIncreaseDetail;


  constructor(private router: Router, public dialog: MatDialog, private authorizationService: AuthorizationService,
     private annualIncreaseService: AnnualIncreaseService, public commonservice: CommonService ) {
  }

  ngOnInit() {

  }

  gotoEditAnnualIncrease() {
    this.router.navigate(['annualIncreaseType/annualIncreaseTypeHome/edit', this.annualIncreaseBatch.annualIncreaseBatchGuid, this.annualIncreaseBatch.feeModel.key]);
   }
  gotoReviewAnnualIncrease() {
    this.router.navigate(['annualIncreaseType/annualIncreaseTypeHome/review', this.annualIncreaseBatch.annualIncreaseBatchGuid, this.annualIncreaseBatch.feeModel.key]);
  }
  gotoViewAnnualIncrease() {
    this.router.navigate(['annualIncreaseType/annualIncreaseTypeHome/view', this.annualIncreaseBatch.annualIncreaseBatchGuid, this.annualIncreaseBatch.feeModel.key]);
  }
  get showEditAI() {

    if (this.annualIncreaseBatch.status.key !== 4884 && this.annualIncreaseBatch.status.key !== 4886 &&
      this.authorizationService.hasPermission('AnnualIncrease', 'Edit', this.annualIncreaseBatch.brand)) {
      return true;
    } else { return false; }
  }
  get showReviewAI() {
    if (this.annualIncreaseBatch.status.key === 4884 &&
      this.authorizationService.hasPermission('ApproveAnnualIncrease', 'Add', this.annualIncreaseBatch.brand)) {
      return true;
    } else { return false; }
  }
  get showViewAI() {
    /*if (this.annualIncreaseBatch.status.key === 4885) {
      return true;
    } else { return false; }*/
    return true;
  }

  get isDelete() {
    if (this.annualIncreaseBatch.status.key !== 4886 && this.annualIncreaseBatch.status.key !== 4884 &&
      this.authorizationService.hasPermission('AnnualIncrease', 'Delete', this.annualIncreaseBatch.brand)) {
      return true;
    } else { return false; }
  }

  openDeleteConfirmation(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '200px';
    dialogConfig.data = { message: 'Are you sure you want to delete Annual Increase Batch?',
     header: 'Delete', reload: false, isCancelNeeded: true, isYesNeeded: true };
    const dialogConfirm = this.dialog.open(ConfirmationComponent, dialogConfig);
    dialogConfirm.componentInstance.onAdd.subscribe((data) => {
      this.annualIncreaseService.deleteAnnualIncrease(this.annualIncreaseBatch.annualIncreaseBatchGuid)
      .subscribe(response => {
        const displayMessage = 'Annual Increase Batch deleted successfully';
        const displayHeader = 'Delete Annual Increase Batch';
        this.openConfirmation(displayMessage, displayHeader);
      },
        error => {
          this.addendumError(error.error.message);
        });
        },
          error => {
            this.addendumError(error.error.message);
          });
    }

    openConfirmation(displayMessage, displayHeader): void {
      const headerMessage = displayHeader;
      const message = displayMessage;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { Id: this.annualIncreaseBatch.annualIncreaseBatchGuid, message: message, header: headerMessage, reload: false,isAnnualIncrease: true};
      this.commonservice.serviceData = dialogConfig.data;
      this.router.navigate(['confirmed/']);
    }
    addendumError(errormessage): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '600px';
      dialogConfig.height = '200px';
      dialogConfig.data = { message: errormessage, header:  'Delete Annual Increase Batch', reload: false };
      const dialogConfirm = this.dialog.open(ConfirmationComponent, dialogConfig);
    }

}
