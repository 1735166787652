import { Component, OnInit, InjectionToken, Inject, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-column-option-select',
  templateUrl: './columnOptionSelect.component.html',
  styleUrls: ['./columnOptionSelect.component.scss']
})
export class ColumnOptionSelectComponent implements OnInit {
  availableData: any = [];
  allData: any = [];
  selectedData: any = [];
  deletedData = [];
  isEditable: boolean;
  header: string;
  flag = true;
  annualIncreaseValue = false;
  onSelectedItemsSave = new EventEmitter();
  onCancelClick = new EventEmitter();
  leftSelect: any;
  rightSelect: any;


  constructor(
    public dialogRef: MatDialogRef<ColumnOptionSelectComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.allData = Object.assign([], this.data.allColumns);
    this.selectedData = Object.assign([], this.data.userColumns);

    this.allData.forEach(item => {
      this.flag = true;
      this.selectedData.forEach(xitem => {
        if (item.columnName === xitem.columnName) {
          this.flag = false;
        }
      });
      if (this.flag) {
        this.availableData.push(item);
      }
    });
    this.isEditable = this.data.editable;
    this.header = this.data.header;
    this.annualIncreaseValue = this.data.annualIncreaseValue;
  }

  moveDown(item): void {
    if (!isNullOrUndefined(item)) {
      const index: number = this.selectedData.indexOf(item);
      if (index !== -1 && index !== this.selectedData.length - 1) {
        const temp = this.selectedData[index + 1];
        this.selectedData[index + 1] = this.selectedData[index];
        this.selectedData[index] = temp;

      }
    }

  }

  moveUp(item): void {
    if (!isNullOrUndefined(item)) {
      const index: number = this.selectedData.indexOf(item);
      if (index !== -1 && index !== 0) {
        const temp = this.selectedData[index - 1];
        this.selectedData[index - 1] = this.selectedData[index];
        this.selectedData[index] = temp;

      }
    }

  }

  onOfficeClick(item): void {
    if (!isNullOrUndefined(item)) {
      const index: number = this.availableData.indexOf(item);
      if (index !== -1) {
        this.availableData.splice(index, 1);
        this.selectedData.push(item);
      }
    }
  }

  onRemoveClick(item): void {
    if (!isNullOrUndefined(item)) {
      const index: number = this.selectedData.indexOf(item);
      if (index !== -1) {
        this.selectedData.splice(index, 1);
        this.availableData.push(item);
      }

    }
  }
  onSelectAll(): void {
    this.availableData.forEach(x => {
      this.selectedData.push(x);
    });
    this.availableData = [];
  }
  onRemoveAll(): void {
    this.selectedData.forEach(x => {
      this.availableData.push(x);
    });
    this.selectedData = [];
  }
  onSave(): void {
    this.onSelectedItemsSave.emit(this.selectedData);
    this.dialogRef.close();
  }
  onRestoreDefault(): void {
    this.allData = Object.assign([], this.data.allColumns);
    this.selectedData = Object.assign([], this.data.defaultGridolumns);
    this.availableData = [];

    this.allData.forEach(item => {
      this.flag = true;
      this.selectedData.forEach(xitem => {
        if (item.columnName === xitem.columnName) {
          this.flag = false;
        }
      });
      if (this.flag) {
        this.availableData.push(item);
      }
    });
  }

  onCancel(): void {
    this.onCancelClick.emit();
    this.dialogRef.close();
    if (this.availableData.reload) {
      window.location.reload();
    }
  }
}
