import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NavBarService {

  visible: boolean;
  title: string;
  sideNavListener: any = new Subject();
  // private toggleSubject = new Subject<any>();
  constructor() {
    this.visible = true;
  }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }

  setTitle(moduleName) {
    this.title = moduleName;
  }

}
