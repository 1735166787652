import { OnInit, Component, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker } from '@angular/material';
import { Router } from '@angular/router';

import { GlobalService } from '../../../shared/services/global.service';
import { Data } from '../../../shared/providers/data';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { StorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SeasonalityService } from '../../../Seasonality/seasonality.service';
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-seasonalitydialog',
  templateUrl: 'seasonalityDialog.component.html',
  styleUrls: ['seasonalityDialog.component.scss'],
})

export class SeasonalityDialogComponent implements OnInit {
  seasonalityForm: FormGroup;
  isLoading = true;
  public checkedKeys: any[] = [];
  showSeasonalityError: boolean;
  minDate: Date = new Date(1900, 1, 1);
  maxDate: Date = new Date(new Date().getFullYear(), 11, 31);
  masterFranchiseList = [];
  mfKeys = [];
  isPageloading: boolean;
  latestYearData = [];
  currentYearData = [];
 
  masterFranchisedropdownSettings = {
    singleSelection: false,
    primaryKey: 'masterFranchiseKey',
    labelKey: 'LegalName',
    enableSearchFilter: true,
    noDataLabel: 'Search Master Franchise...',
    disabled: false,
    text: '--SELECT--',
    badgeShowLimit: 3,
    classes: 'ddlFilter wid60 searchSection'
  };
 
  ngOnInit() {
    this.isLoading = false;
    // this.masterFranchiseList = [];
    this.globalService.getMasterFranchise(true)
      .subscribe(data => {

        this.masterFranchiseList = data.sort((a, b) => a.LegalName.localeCompare(b.LegalName));
        this.isLoading = false;
      });

  }
  constructor(
    public dialogRef: MatDialogRef<SeasonalityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, private router: Router, public ref: ChangeDetectorRef, private globalService: GlobalService, public localData: Data,
     @Inject(SESSION_STORAGE) private storage: StorageService,public seasonalityService: SeasonalityService) {
    this.createForm();
    const date = new Date();
    this.seasonalityForm.get('year').setValue(date.getFullYear());
   this.seasonalityForm.get('year').updateValueAndValidity();
   this.isLoading = false;
  }
  createForm() {
    this.seasonalityForm = this.fb.group({
      mfKey: ['', Validators.required],
      calendarYear:  new FormControl(moment(), [Validators.required]),
      year: ['', Validators.required]
    });
  }
  onSubmit() {
    if (this.seasonalityForm.valid) {
      this.checkExistingData();
    } else {
      this.showSeasonalityError = this.checkedKeys.length > 0 ? false : true;
      this.validateControls(this.seasonalityForm);
    }
  }
  validateControls(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.validateControls(abstractControl);
      } else {
        abstractControl.markAsDirty();
        abstractControl.markAsTouched();
      }
    });
  }
  checkExistingData(){
    this.isPageloading = false;
    const formModel = this.seasonalityForm.value;
    this.mfKeys = [];
    formModel.mfKey.forEach(key => {
      this.mfKeys.push(key.masterFranchiseKey);
      });
    this.seasonalityService.getSeasonality(formModel.year, this.mfKeys, true)
      .subscribe(seasonality => {
        this.latestYearData = seasonality.filter(data => data.year != formModel.year);
        this.currentYearData = seasonality.filter(data => data.year == formModel.year);
        if (this.latestYearData.length > 0) {
          this.onGo();
        } else {
  this.isPageloading = true;
        }
        this.ref.detectChanges();
      });
     
  }
  onGo(): void {
    const formModel = this.seasonalityForm.value;
    this.storage.set(`masterFranchise`, formModel.mfKey);
    this.storage.set(`year`, formModel.year);
    this.router.navigateByUrl('blank').then(() => {
      this.router.navigate(['seasonality/add']);
    });
    this.dialogRef.close();
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  hasError(ctrlName: string, error: string) {
    return this.seasonalityForm.get(ctrlName)
      && this.seasonalityForm.get(ctrlName).invalid
      && (this.seasonalityForm.get(ctrlName).dirty || this.seasonalityForm.get(ctrlName).touched)
      && this.seasonalityForm.get(ctrlName).hasError(error);
  }

  yearSelectedHandler(chosenDate: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    this.setYearFromDate(chosenDate);
  }
  setYearFromDate(chosenDate: Moment) {
    if (chosenDate) {
      chosenDate.set({ date: 1, month: 0 });
      this.seasonalityForm.get('calendarYear').setValue(chosenDate, { emitEvent: false });
      this.seasonalityForm.get('year').setValue(chosenDate.year(), { emitEvent: false });
    }
  }
  setYear() {
    const chosenDate = this.seasonalityForm.get('calendarYear').value;
    this.setYearFromDate(chosenDate);
  }
  onAllMFDeSelect(event){
    this.seasonalityForm.get('mfKey').setValue(null);
    this.seasonalityForm.get('mfKey').updateValueAndValidity();
  }
}
