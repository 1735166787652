import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { AuthorizationService } from '../core/auth/authorization.service';

@Directive({
  selector: '[appHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {

  @Input('resource') resource: string;
  @Input('operation') operation: string;
  @Input('brand') brand: string;
  constructor(private el: ElementRef, private authorizationService: AuthorizationService) { }

  ngOnInit() {
    if (!this.authorizationService.hasPermission(this.resource, this.operation, this.brand)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
