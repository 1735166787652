import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOffice } from '../models/office';
import { AuthorizationService } from '../../core/auth/authorization.service';

@Component({
  selector: 'app-executedofficesactions-overlay',
  templateUrl: './executedofficesactions-overlay.component.html',
  styleUrls: ['./executedofficesactions-overlay.component.scss']
})
export class ExecutedofficesactionsOverlayComponent implements OnInit {
  office: IOffice;


  constructor(private router: Router, private authorizationService: AuthorizationService) {
  }

  ngOnInit() {

  }

  gotoEnterIFFDetailPage() {
    this.router.navigate(['/initialfranchisefee/edit', this.office.id]);
  }
  gotoReviewIFFDetailPage() {
    this.router.navigate(['/initialfranchisefee/review', this.office.id]);
  }
  gotoViewIFFDetailPage() {
    this.router.navigate(['/initialfranchisefee/view', this.office.id]);
  }
  get showEditIFF() {
    if (this.office.iffStatus.key !== 4854 && this.authorizationService.hasPermission('IFF', 'Add', null)) {
      return true;
    } else { return false; }
  }
  get showReviewIFF() {
    if (this.office.iffStatus.key === 4854 && this.authorizationService.hasPermission('ApproveIFF', 'Add', null)) {
      return true;
    } else { return false; }
  }
  get showViewIFF() {
    if ((this.office.iffStatus.key === 4854 || this.office.iffStatus.key === 1445) && this.authorizationService.hasPermission('IFF', 'View', null)) {
      return true;
    } else { return false; }
  }

}
