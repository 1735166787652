import { Component, Inject, OnInit, ChangeDetectorRef, NgModule, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContractService } from '../../../contract/contract.service';
import { IAgreementTypeInfo } from '../../../shared/models/agreementTypeInfo';
import { GlobalService } from '../../../shared/services/global.service';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'app-contract-type',
  templateUrl: 'contracttypeDialog.component.html',
  styleUrls: ['contracttypeDialog.component.scss'],
})
export class ContractTypeDialogOverviewComponent implements OnInit {

  brand: any[] = [
    {
      code: 'BHG',
      description: 'Better Homes and Gardens',
    },
    {
      code: 'C21',
      description: 'Century 21',
    },
    {
      code: 'CB',
      description: 'Coldwell Banker',
    },
    {
      code: 'CBC',
      description: 'Coldwell Banker Commercial',
    },
    //{
    //  key: 10,
    //  code: 'COR',
    //  description: 'Corcoran Group',
    //},
    {
      code: 'ERA',
      description: 'ERA',
    },
    {
      code: 'SIR',
      description: 'Sotheby\'s International Realty',
    },
    //CLB Masking
    //{
    //  key: 9,
    //  code: 'CLB',
    //  description: 'Climb Real Estate',
    //},
    
  ];

  country: any[];
  currency: any[];
  brandSetting: IAgreementTypeInfo[];
  selectedCurrency: any;
  selectedCountry: any;
  masterfranchisekey: any;
  selectedBrand: any;

  ngOnInit() {
    this.selectedBrand='';
    this.selectedCountry='';
    this.selectedCurrency='';
    // this.getCountryList(this.selectedBrand);
  }

  constructor(
    public dialogRef: MatDialogRef<ContractTypeDialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router
    , private contractService: ContractService
    , private globalService: GlobalService) {
  }

  onGo(): void {
    if (this.selectedCurrency !== '' && this.selectedCountry !== '' && this.selectedBrand !== '') {
      this.router.navigate(['/contract/edit/', this .selectedBrand, this.selectedCountry, this.selectedCurrency, this.masterfranchisekey]);
      this.dialogRef.close();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onBrandChange(value: any): void {
    this.getCountryList(this.selectedBrand);
  }

  onCountryChange(): void {
    if(this.selectedCountry !== ''){
    this.currency = [];
    const countrySetting = this.brandSetting.filter(c => c.country.code === this.selectedCountry)[0];
    this.currency = countrySetting.currencies;
    this.masterfranchisekey = countrySetting.masterFranchise[0].masterFranchiseKey;
    this.selectedCurrency = this.currency[0].description;
  }
  }

  getCountryList(brand): void {
    this.country = [];
    this.globalService.getAgreementConfig(brand)
      .subscribe(data => {
        if (data !== null) {

          this.brandSetting = data;
          data.forEach(element => {
            this.country = [...this.country, element.country];
            if (element.country.code === 'US') {
              this.selectedCountry = element.country.code;
              this.onCountryChange();
            }
          });
        }
      });
  }
}
