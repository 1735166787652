import {Directive, ElementRef, Output, EventEmitter, HostListener, OnInit, OnDestroy} from '@angular/core';
import { Observable } from 'rxjs';



 
@Directive({
    selector: '[click-outside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef : ElementRef) {
    }
 
    @Output()
    public clickOutside = new EventEmitter();
 
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (clickedInside) {
            this.clickOutside.emit({
                target: (event.target || null),
                value: false
              });
        }
        else{
            this.clickOutside.emit({
                target: (event.target || null),
                value: true
              });
        }
    }
  }
