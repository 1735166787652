import { Injectable, EventEmitter, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IFranchiseAgreement } from '../billingterms/franchise-agreement';
import { State, process } from '@progress/kendo-data-query';
import { IContract } from '../contract/contract-template';
import { IAgreementCriteria } from '../billingterms/agreementCriteria';
import { map } from 'rxjs/operators';
import { IGridColumn } from '../shared/models/gridColumn';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { IannualIncreaseDetail } from './annualIncreaseDetail';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AnnualIncreaseService extends BehaviorSubject<GridDataResult> {
  approveDirect: string;
  saveUrl: string;
  searchVal: string;
  dataUpdated = new EventEmitter<boolean>();
  dataUpdatedOfficesection = new EventEmitter<boolean>();
  private gridColumnServiceUrl;
  private annualIncreaseServiceUrl;
  rows = [];
  officeRows = [];
  public searchCriteria: IAgreementCriteria;
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService, private http: HttpClient) {
    super(null);
    this.gridColumnServiceUrl = environment.apiBaseUrl + 'v1/gridcolumns';
    this.annualIncreaseServiceUrl = environment.apiBaseUrl + 'v1/annualIncrease';
    this.searchCriteria = {
      recordCount: 50,
      sortBy: 'lastupdateon',
      sortOrder: 'desc',
      includeOnlyPendingAddendemSetup: false,
      includeOnlyPendingApproval: false,
      includeOnlyPendingBillingTermsSetup: false,
      includeOnlyLegalNoticeContact: false,
      status: { key: null }
    };
  }

  public filterAnnualIncreaseAgreements(annualIncreaserows: any, state: any): void {
    this.processAnnualIncreaseAgreements(annualIncreaserows, state)
      .then(x => {
        super.next(x);
        this.dataUpdated.emit(true);
      });
  }

  getFilteredAnnualIncreaseAgreements(searchCriteria: any, billingkey): Observable<any> {
    if (billingkey === 4409 || billingkey === 4408) {
      this.searchVal = '/royaltyannualincreaselist';
    } else {
      this.searchVal = '/annualincreaselist';
    }
    return this.http.post<IFranchiseAgreement[]>(this.annualIncreaseServiceUrl + this.searchVal, searchCriteria);
  }
  getAnnualIncreaseAgreementCount(searchCriteria: any, billingkey): Observable<any> {
    if (billingkey === 4409 || billingkey === 4408) {
      this.searchVal = '/annualincreaseRoyaltyAgreement';
    } else {
      this.searchVal = '/annualincreaseAgreement';
    }
    return this.http.post<IFranchiseAgreement[]>(this.annualIncreaseServiceUrl + this.searchVal , searchCriteria);
  }

  getannualIncreaseOfficeOverrides(searchCriteria: any): Observable<any> {
    return this.http.post<IFranchiseAgreement[]>(this.annualIncreaseServiceUrl + '/annualincreaseofficeoverrideslist ', searchCriteria);

  }
  public filterAnnualIncreaseOfficeOverrides(annualIncreaseofficerows: any, state: any): void {
    this.processAnnualIncreaseOfficeOverrides(annualIncreaseofficerows, state)
      .then(x => {
        super.next(x);
        this.dataUpdatedOfficesection.emit(true);
      });
  }

  queryAnnualIncreaseRows(annualIncreaserows: any, state: any): Promise<GridDataResult> {
    return this.processAnnualIncreaseAgreements(annualIncreaserows, state);
  }

  queryOfficeOverrideRows(officeRows: any, state: any): Promise<GridDataResult> {
    return this.processAnnualIncreaseOfficeOverrides(officeRows, state);
  }
  private async processAnnualIncreaseAgreements(annualIncreaserows: any, state: any): Promise<GridDataResult> {

    annualIncreaserows.forEach(item => {
      if (item.effectiveTo != null) {
        item.effectiveTo = new Date(item.effectiveTo);
      }
      if (item.statusEffectiveSince != null) {
        item.statusEffectiveSince = new Date(item.statusEffectiveSince);
      }
      if (item.termStartDate != null) {
        item.termStartDate = new Date(item.termStartDate);
      }
      if (item.termEndDate != null) {
        item.termEndDate = new Date(item.termEndDate);
      }

      if (item.lastUpdated != null) {
        item.lastUpdated.on = new Date(item.lastUpdated.on);
      }
      if (item.versionDate != null) {
        const d = new Date(item.versionDate);
        let month = (d.getMonth() + 1).toString();
        const year = (d.getFullYear()).toString();
        let date = (d.getDate()).toString();

        month = (month.length === 1) ? ('0' + month) : month;
        date = (date.length === 1) ? ('0' + date) : date;

        const mmddyyyyy = month + '/' + date + '/' + year;
        item.contractTypeNameDate = item.contractType + ' - ' + mmddyyyyy;
      }
      if (!isNullOrUndefined(item.bMFTerm) && !isNullOrUndefined(item.bMFTerm.feeDefinitions) && item.bMFTerm.feeDefinitions.length > 0) {
        item.tieredSection = { tiers: [], proposedSection: false };
        item.proposedSection = { tiers: [], proposedSection: true };
        item.tieredSection.tiers = Object.assign([], item.bMFTerm.feeDefinitions);
        item.proposedSection.tiers = Object.assign([], item.bMFTerm.feeDefinitions);
      }
      if (!isNullOrUndefined(item.royaltyTerm) && !isNullOrUndefined(item.royaltyTerm.feeDefinitions) && item.royaltyTerm.feeDefinitions.length > 0) {
        item.tieredSection = { tiers: [], proposedSection: false };
        item.proposedSection = { tiers: [], proposedSection: true };
        item.tieredSection.tiers = Object.assign([], item.royaltyTerm.feeDefinitions);
        item.proposedSection.tiers = Object.assign([], item.royaltyTerm.feeDefinitions);
      }
    });
    this.rows = annualIncreaserows;
    return process(this.rows, state);
  }

  private async processAnnualIncreaseOfficeOverrides(annualIncreaseofficerows: any, state: any): Promise<GridDataResult> {

    annualIncreaseofficerows.forEach(item => {
      if (item.expiresOn != null) {
        item.expiresOn = new Date(item.expiresOn);
      }
      if (item.officeInfo.expiresOn != null) {
        item.officeInfo.expiresOn = new Date(item.officeInfo.expiresOn);
      }
      if (item.effectiveTo != null) {
        item.effectiveTo = new Date(item.effectiveTo);
      }
      if (item.statusEffectiveSince != null) {
        item.statusEffectiveSince = new Date(item.statusEffectiveSince);
      }
      if (item.lastUpdated != null) {
        item.lastUpdated.on = new Date(item.lastUpdated.on);
      }
    });
    this.officeRows = annualIncreaseofficerows;
    return process(this.officeRows, state);
  }

  getAnnualIncreaseColumns(modelKey): Observable<IGridColumn[]> {

    if (modelKey === 3448 || modelKey === 4525 || modelKey === 4686) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/minmaxAnnualIncrease`);
    } else if (modelKey === 3449 || modelKey === 4409 || modelKey === 4619) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/tieredAnnualIncrease`);
    } else if (modelKey === 4408) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/royaltyCapAnnualIncrease`);
     } else {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/flatFeeAnnualIncrease`);
    }

  }

  getDefaultAnnualIncreaseColumns(modelKey): Observable<IGridColumn[]> {
    if (modelKey === 3448 || modelKey === 4525 || modelKey === 4686) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/minmaxAnnualIncrease/default`);
    } else if (modelKey === 3449 || modelKey === 4409 || modelKey === 4619) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/tieredAnnualIncrease/default`);
    } else if (modelKey === 4408) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/royaltyCapAnnualIncrease/default`);
     } else {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/flatFeeAnnualIncrease/default`);
    }
  }

  getAnnualIncreaseUserGridColumns(modelKey): Observable<IGridColumn[]> {
    if (modelKey === 3448 || modelKey === 4525 || modelKey === 4686) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/minmaxAnnualIncrease/user`);
    } else if (modelKey === 3449 || modelKey === 4409 || modelKey === 4619) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/tieredAnnualIncrease/user`);
    } else if (modelKey === 4408) {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/royaltyCapAnnualIncrease/user`);
     } else {
      return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/flatFeeAnnualIncrease/user`);
    }
  }

  saveUserAnnualIncreaseGridColumns(userGridColoumns: IGridColumn[], modelKey: number): Observable<any> {
    let url = `${this.gridColumnServiceUrl}/minmaxAnnualIncrease`;
    if (modelKey === 3449 || modelKey === 4409 || modelKey === 4619) {
      url = `${this.gridColumnServiceUrl}/tieredAnnualIncrease`;
    } else if (modelKey === 4526) {
      url = `${this.gridColumnServiceUrl}/flatFeeAnnualIncrease`;
    } else if (modelKey === 4408) {
      url = `${this.gridColumnServiceUrl}/royaltyCapAnnualIncrease`;
     }

    return this.http.post(url, userGridColoumns);
  }

  getAnnualIncreaseOfficeOverrideColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/officeOverride`);
  }

  getDefaultAnnualIncreaseOfficeOverrideColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/officeOverride/default`);
  }

  getAnnualIncreaseOfficeOverrideUserGridColumns(): Observable<IGridColumn[]> {
    return this.http.get<IGridColumn[]>(`${this.gridColumnServiceUrl}/officeOverride/user`);
  }

  saveUserOfficeOverrideGridColumns(userGridColoumns: IGridColumn[]): Observable<any> {
    const url = `${this.gridColumnServiceUrl}/officeOverride`;
    return this.http.post(url, userGridColoumns);
  }

  saveAnnualIncreaseDetails(annualIncreaseDetails: IannualIncreaseDetail, billingkey): Observable<any> {
    if (billingkey === 4409 || billingkey === 4408) {
       this.saveUrl = `${this.annualIncreaseServiceUrl}/SaveRoyaltyAnnualIncrease`;
    } else {
      this.saveUrl = `${this.annualIncreaseServiceUrl}/SaveAnnualIncrease`;
    }
    return this.http.post(this.saveUrl, annualIncreaseDetails);
  }
  getAnnualIncreaseDetails(id: any, billingkey): Observable<any> {
    if (billingkey === '4409' || billingkey === '4408') {
      return this.http.get<IannualIncreaseDetail>(`${this.annualIncreaseServiceUrl}/${id}/royaltyAnnualIncrease`);
    } else {
      return this.http.get<IannualIncreaseDetail>(`${this.annualIncreaseServiceUrl}/${id}`);
    }
   }
  approveAnnualIncrease(id: string, approvalComment: any, billingkey): Observable<any> {
    const agreementComment = {
      comment: approvalComment
    };
    if (billingkey === 4409 || billingkey === 4408) {
      this.approveDirect = `/approveRoyalty`;
   } else {
     this.approveDirect = '/approve';
   }
    return this.http.post(this.annualIncreaseServiceUrl+'/' + id + this.approveDirect, agreementComment);
  }

  rejectAnnualIncrease(id: string, rejectComment: any): Observable<any> {
    const agreementComment = {
      comment: rejectComment
    };

    return this.http.post(this.annualIncreaseServiceUrl + '/' + id + '/reject', agreementComment);
  }
  deleteAnnualIncrease(annualIncreaseBatchGuid: string): Observable<any> {
    return this.http.delete(this.annualIncreaseServiceUrl + annualIncreaseBatchGuid);
  }
}
