import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../core/auth/authorization.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';

import { IFirmSearchResult } from '../../payables/firm';



@Component({
  selector: 'app-firmactions-overlay',
  templateUrl: './firmactions-overlay.component.html',
  styleUrls: ['./firmactions-overlay.component.scss']
})
export class FirmActionsOverlayComponent implements OnInit {
  firm: IFirmSearchResult;

  constructor(private router: Router, private authorizationService: AuthorizationService,
    public dialog: MatDialog, private commonservice: CommonService
  ) {
  }

  ngOnInit() {
  }

  canGeneratePaymentRequest() {
    const hasPermission = this.authorizationService.hasPermission('RnIPaymentRequest', 'Add', this.firm.company.brand);
    if (this.firm && this.firm.status && hasPermission) {
      return this.firm.status.description === 'Active';
    }
    return false;
  }

  goToGeneratePayment() {
    const type = this.firm.firmType.description !== 'Incentive Programs' ? 'rebate' : 'incentive';
    this.router.navigate(['/payables/generatePaymentRequest/', type, this.firm.id
    ]);
  }

  canShowManageAGC() {
    console.log(this.firm);
    const hasAdjustAgc = this.authorizationService.hasPermission('AGCAdjustment', 'Add', this.firm.company.brand);
    if (this.firm && this.firm.status && hasAdjustAgc) {
      return this.firm.status.description === 'Active';
    }
    return false;
  }

  gotoView() {
    const hasViewAccess = this.authorizationService.hasPermission('Firm', 'View', this.firm.company.brand)
    if (hasViewAccess) {
      if (this.firm && this.firm.firmType && this.firm.firmType.code === '303') {
        this.router.navigate(['/payables/incentives/view/', this.firm.id]);
      } else {
        this.router.navigate(['/payables/rebates/view/', this.firm.id]);
      }
    }
  }

  gotoManageAGC() {
    this.router.navigate(['/payables/manageAGC/', this.firm.id]);
  }

}
