import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from '../core/base/base.component';
import { NavBarService } from '../core/nav/nav-bar.service';
import { MonitorService } from '../core/services/monitor.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: any
    , private nav: NavBarService
    , monitorService: MonitorService) {
    super(monitorService);
    nav.hide();
  }

  ngOnInit() {
  }

  connect() {
    this.document.location.href = 'dashboard';
  }
}
