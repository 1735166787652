import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { InvalidpageComponent } from './shared/invalidpage/invalidpage.component';
import { ErrorsComponent } from './core/errors/errors.component';
import { InvalidActionComponent } from './shared/invalidAction/invalidAction.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'error',
    component: ErrorsComponent,
  },
  {
    path: '404',
    component: InvalidpageComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'invalidAction',
    component: InvalidActionComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'dashboard',
    data: {
      title: 'Home',
      resource: ['FranchiseAgreement'],
      secureAction: ['View']
    },
    loadChildren: () => import('../app/dashboard/dashboard.module').then(m => m.DashBoardModule)
  },
  {
    path: 'agreementterms',
    data: {
      title: 'Agreement Terms',
      resource: ['BillingTerms'],
      secureAction: ['View']
    },
    loadChildren: () => import('../app/billingterms/billingterms.module').then(m => m.BillingtermsModule)
  },
  {
    path: 'reports',
    data: {
      title: 'Business Intelligence'
    },
    loadChildren: () => import('../app/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'contract',
    data: {
      title: 'Tools'
    },
    loadChildren: () => import('../app/contract/contract.module').then(m => m.ContractModule)
  },
  {
    path: 'search',
    loadChildren: () => import('../app/search/search.module').then(m => m.SearchModule)

  },
  {
    path: 'profile',
    loadChildren: () => import('../app/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'addendum',
    loadChildren: () => import('../app/addendum/addendum.module').then(m => m.AddendumModule)
  },
  {
    path: 'confirmed',
    loadChildren: () => import('../app/shared/actionConfirmed/actionConfirmed.module').then(m => m.ActionConfirmedModule)
  },
  {
    path: 'initialfranchisefee',
    data: {
      title: 'Initial Franchise Fee',
      resource: ['Charge'],
      secureAction: ['Add']
    },
    loadChildren: () => import('../app/initialfranchisefee/initialfranchisefee.module').then(m => m.InitialFranchiseFeeModule)
  },
  {
    path: 'annualIncreaseType',
    data: {
      title: 'Annual Increase'
    },
    loadChildren: () => import('../app/annualIncrease/annualIncrease.module').then(m => m.AnnualIncreaseModule)
  },
  {
    path: 'responsibleBroker',
    data: { title: 'Add Responsible Broker' },
    loadChildren: () => import('../app/responsibleBroker/responsible-broker.module').then(m => m.ResponsibleBrokerModule)
  },
  {
    path: 'legalContact',
    data: { title: 'Add Legal Contact' },
    loadChildren: () => import('../app/legalContact/legal-contact.module').then(m => m.LegalContactModule)
  },
  {
    path: 'payables',
    data: {
      title: 'Payables',
      resource: ['BillingTerms'],
      secureAction: ['View']
    },
    loadChildren: () => import('../app/payables/payables.module').then(m => m.PayablesModule)
  },
  {
    path: 'seasonality',
    data: {
      title: 'Seasonality'
    },
    loadChildren: () => import('../app/Seasonality/seasonality.module').then(m => m.SeasonalityModule)
  },
  {
    path: 'confirmed',
    loadChildren: () => import('../app/shared/actionConfirmed/actionConfirmed.module').then(m => m.ActionConfirmedModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
