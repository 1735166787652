import { Component, OnInit, Inject, EventEmitter, Output, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sessionTimeOut',
  templateUrl: './sessionTimeOut.component.html',
  styleUrls: ['./sessionTimeOut.component.scss']
})
export class SessionTimeOutComponent implements OnInit {
  sessionData: any;
  @Input() countDown: number;
  constructor(
    public dialogRef: MatDialogRef<SessionTimeOutComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.sessionData = this.data;
  }

  onOk(): void {
    this.dialogRef.close();
    this.data.timer.watch();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.data.timer.watch();
  }
}
