import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../core/auth/authorization.service';
import { IPaymentRequest } from '../../payables/firm-forecast';

@Component({
  selector: 'app-paymentrequestactions-overlay',
  templateUrl: './paymentrequestactions-overlay.component.html',
  styleUrls: ['./paymentrequestactions-overlay.component.scss']
})
export class PaymentRequestActionsOverlayComponent implements OnInit {
    paymentRequest: IPaymentRequest;


  constructor(private router: Router, private authorizationService: AuthorizationService) {
  }

  ngOnInit() {

  }

 
  gotoReviewPaymentRequest() {
      console.log(this.paymentRequest);
    this.router.navigate(['/payables/paymentRequest/review/', this.paymentRequest.paymentRequestGuid]);
  }
  canShowReviewPayment() {
    return this.authorizationService.hasPermission('ApproveRnIPaymentRequest', 'View', this.paymentRequest.firm.primaryAgreementInfo.company.brand);
    
  }
}
