import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker } from '@angular/material';
import { StorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { CommonService } from '../../../shared/services/common.service';
import {GlobalService} from '../../../shared/services/global.service'
@Component({
  selector: 'app-accrualDialog',
  templateUrl: './accrualDialog.component.html',
  styleUrls: ['./accrualDialog.component.scss']
})
export class AccrualDialogComponent implements OnInit {

  allBrands: any[] = [
    {
      key: 1,
      code: 'BHG',
      description: 'Better Homes and Gardens',
    },
    {
      key: 2,
      code: 'C21',
      description: 'Century 21',
    },
    {
      key: 3,
      code: 'CB',
      description: 'Coldwell Banker',
    },
    {
      key: 4,
      code: 'CBC',
      description: 'Coldwell Banker Commercial',
    },
    // {
    //   key: 9,
    //   code: 'CLB',
    //   description: 'Climb Real Estate',
    // },
    // {
    //   key: 10,
    //   code: 'COR',
    //   description: 'Corcoran Group',
    // },
    {
      key: 5,
      code: 'ERA',
      description: 'ERA',
    },
    {
      key: 6,
      code: 'SIR',
      description: 'Sotheby\'s International Realty',
    }
  ];
  branddropdownSettings = {
    singleSelection: false,
    primaryKey: 'key',
    labelKey: 'code',
    enableSearchFilter: true,
    disabled: false,
    text: '--SELECT--',
    badgeShowLimit: 3,
    classes: 'ddlFilter wid60 searchSection',
    maxHeight: 200
  };
  brands = [];
  accuralForm: FormGroup;
  date: Date = new Date();
  isLoading = true;
  validationMessage: string;
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService, public commonservice: CommonService, private router: Router, public dialogRef: MatDialogRef<AccrualDialogComponent>,
    private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,public globalService: GlobalService) {
    this.createForm();
  }

  ngOnInit() {
    this.isLoading = false;
    this.getUserBrands();
  }

  createForm() {
    this.accuralForm = this.fb.group({
      brandName: ['', Validators.required],
      calendarYear: [{
        value: this.date.getFullYear(),
        disabled: true
      }]
    });
  }

  getUserBrands() {
    if (this.storage.get(`brands`)) {
      const userBrands = this.storage.get(`brands`);
      this.allBrands.forEach(brand => {
        userBrands.forEach(uBrand => {
          if (uBrand === brand.code) {
            this.brands.push(brand);
          }
        });
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    /*first api will be called to check if any of the selected brands are in progress,
    if yes then second api nested inside first, will not run accural and isAccuralInProgress() will show the error*/

 let selectedBrands= this.accuralForm.get('brandName').value;
this.globalService.runAccruals(selectedBrands).subscribe(data=>{
  this.commonservice.serviceData = {isRunAccural: true, message: 'Changes will reflect once the recalculation is done.', header: 'Accrual Utility'}
  this.dialogRef.close();
  this.router.navigate(['confirmed/']);
},
error => {
  if(error.error){
    this.validationMessage=error.error.errorCodes[0].value+'. Please wait for the current run to complete.';
  }
})
   
    
  }

  

  onAllBrandDeSelect(event) {
    this.accuralForm.get('brandName').setValue(null);
    this.accuralForm.get('brandName').updateValueAndValidity();
  }

}
