import { Component, OnInit, OnDestroy, Inject, ElementRef } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent, NavigationCancel, NavigationStart, NavigationError } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AdalService } from 'adal-angular4';
import { Subscription } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES, EventTargetInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionTimeOutComponent } from './shared/sessionTimeOut/sessionTimeOut.component';
import { StorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { config } from './config';


@Component({
  selector: 'app-root',
  template: `<div *ngIf="loading">
  <span class="pageLoading k-icon k-i-loading" style="color: #292c66"></span>
  </div>
  <app-navbar [hidden]="loading"></app-navbar>`,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  sub: Subscription;
  loading = true;
  minCountDown = 0;
  secCountDown = 0;
  private dialogRef: MatDialogRef<SessionTimeOutComponent>;
  idleState = 'NOT_STARTED';
  timedOut = false;
  constructor(private element: ElementRef, private adalSvc: AdalService
    , private router: Router
    , private activatedRoute: ActivatedRoute
    , public translate: TranslateService
    , private idle: Idle
    , private keepalive: Keepalive
    , public dialog: MatDialog
    , @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {

    this.adalSvc.init(environment.adalConfig);

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    translate.setDefaultLang('en');
    translate.use('en');
    // sets an idle timeout of 15 minutes.
    this.idle.setIdle(config.SessionIdleTime);
    // sets a timeout period of 5 minutes.
    this.idle.setTimeout(config.SessionTimeOut);
    this.idle.onIdleEnd.subscribe(() => {
      // this.idle.watch();
      this.idleState = 'NO_LONGER_IDLE';
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      this.dialogRef.close();
      this.logOut();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE_START';
      this.openSessionPopUp();
    });
    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      this.secCountDown = countdown;
      if (this.dialogRef && this.dialogRef.componentInstance) {
        const minutes = Math.floor(countdown / 60);
        const seconds = countdown - minutes * 60;
        this.dialogRef.componentInstance.data.secCountDown = seconds;
        this.dialogRef.componentInstance.data.minCountDown = minutes;
      }
      if (countdown <= 0) {
        this.logOut();
      }
    });
    this.idle.watch();
    this.keepalive.interval(15);
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;

    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  ngOnInit() {
    this.adalSvc.handleWindowCallback();
  }

  logOut() {
    this.resetTimeOut();
  }

  resetTimeOut() {
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.adalSvc.logOut();

  }

  openSessionPopUp(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '200px';
    dialogConfig.data = {
      message: 'You have been inactive for 15 minutes. If you continue to stay idle, you will be logged out in ',
      header: 'Session Out', minuteCountDown: this.minCountDown, secondCountDown: this.secCountDown, timer: this.idle
    };

    this.dialogRef = this.dialog.open(SessionTimeOutComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
