import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material';

const Year_Date_Format = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Directive({
  selector: '[appYearpicker]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: Year_Date_Format},
  ]
})
export class YearpickerDirective {

  constructor() { }

}
