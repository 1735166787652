import { Component, OnInit, ReflectiveInjector, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MonitorService } from '../services/monitor.service';

@Component({
  template: ''
})
export class BaseComponent implements OnInit, AfterViewChecked {

  constructor(public monitorService: MonitorService) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
  }
}

