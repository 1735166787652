import { Component, OnInit, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, Validators, FormArray, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { IseasonalityFactorDetails } from '../../../../app/dashboard/seasonalityFactorDetails';
import { SeasonalityService } from '../../../../app/Seasonality/seasonality.service';

@Component({
  selector: 'app-edit-seasionality',
  templateUrl: './edit-seasionality.component.html',
  styleUrls: ['./edit-seasionality.component.scss']
})
export class EditSeasionalityComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  seasionality: IseasonalityFactorDetails;
  isLoading = true;
  isFormInValid = false;
  errorMessage = '';

  seasionalityForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditSeasionalityComponent>,
    public seasionalityService: SeasonalityService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: FormBuilder) {
  }

  createForm() {
    this.seasionalityForm = this.fb.group({
      jan: [this.seasionality.January, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      feb: [this.seasionality.February, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      mar: [this.seasionality.March, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      apr: [this.seasionality.April, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      may: [this.seasionality.May, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      jun: [this.seasionality.June, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      jul: [this.seasionality.July, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      aug: [this.seasionality.August, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      sep: [this.seasionality.September, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      oct: [this.seasionality.October, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      nov: [this.seasionality.November, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]],
      dec: [this.seasionality.December, [Validators.required, Validators.max(100), Validators.pattern(/^([0-9]\d*)(\.([0-9]{0,2}))?$/)]]
    });
  }

  ngOnInit() {
    this.seasionality = this.data.seasionality;
    this.isLoading = false;
    this.createForm();
  }

  Cancel() {
    this.dialogRef.close();
  }

  Save() {
    const data = this.prepareSeasionality();
    const decimalPointFactor = 1e10;
    let total =
      (parseFloat(data.January) * decimalPointFactor +
        parseFloat(data.February) * decimalPointFactor +
        parseFloat(data.March) * decimalPointFactor +
        parseFloat(data.April) * decimalPointFactor +
        parseFloat(data.May) * decimalPointFactor +
        parseFloat(data.June) * decimalPointFactor +
        parseFloat(data.July) * decimalPointFactor +
        parseFloat(data.August) * decimalPointFactor +
        parseFloat(data.September) * decimalPointFactor +
        parseFloat(data.October) * decimalPointFactor +
        parseFloat(data.November) * decimalPointFactor +
        parseFloat(data.December) * decimalPointFactor) / decimalPointFactor;

    if (total.toFixed(2) === '100.00' && this.seasionalityForm.valid) {

      this.isFormInValid = false;
      this.isLoading = true;
      this.seasionalityService.saveSeasonality([data])
        .subscribe(response => {
          this.onSave.emit(true);
          this.dialogRef.close();
        }, error => {
          this.onSave.emit(false);
          this.dialogRef.close();
        });
    } else {
      if (total.toFixed(2) !== '100.00') {
        this.errorMessage = 'Seasonality Factor should sum up to 100.';
      }

      this.isFormInValid = true;
    }
  }

  prepareSeasionality(): IseasonalityFactorDetails {
    const formModel = this.seasionalityForm.value;
    const saveSeasonality: IseasonalityFactorDetails = {
      MasterFranchiseKey: this.seasionality.MasterFranchiseKey,
      January: formModel.jan,
      February: formModel.feb,
      March: formModel.mar,
      April: formModel.apr,
      May: formModel.may,
      June: formModel.jun,
      July: formModel.jul,
      August: formModel.aug,
      September: formModel.sep,
      October: formModel.oct,
      November: formModel.nov,
      December: formModel.dec,

      countryCode: this.seasionality.countryCode,
      year: this.seasionality.year,
      rfgBrand: this.seasionality.rfgBrand,
      created: this.seasionality.created,
      lastUpdate: this.seasionality.lastUpdate
    };
    return saveSeasonality;
  }

  hasError(ctrlName: string, error: string) {

    return this.seasionalityForm.get(ctrlName).invalid
      && (this.seasionalityForm.get(ctrlName).dirty || this.seasionalityForm.get(ctrlName).touched)
      && this.seasionalityForm.get(ctrlName).hasError(error);

  }

}
